import "./cookie_preferences.css";
import React from "react";
import WoofRoundedButton from "./rounded_woof_button";
import { copyParamsToNewLink } from "../services/analytics";

const options = ["Nödvändiga", "Statistik", "Analys", ];
const forcedOptions = ["Nödvändiga"];

export default function CookiePreferences(props) {
  const [acceptedValues, setAcceptedValues] = React.useState(options);

  const { isVisible, onClose } = props;
  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <p className="popup_title">Hantera inställningar</p>
        <p
          style={{
            paddingTop: "10px",
            paddingBottom: "20px",
          }}
        >
          Vi använder Cookies, mobila analystjänster och marknadsförings-ID och
          liknande tekniker för att driva Woofs webbplats. Vi ber dig att
          uppmärksamma att om du väljer att inte acceptera eller ta bort cookies
          så kan det innebära att vissa funktioner eller tjänster på Woofs
          Webbplats inte fungerar korrekt. Läs mer om hur vi hanterar cookies i
          vår{" "}
          <a href={copyParamsToNewLink("/integritetspolicy")}>
            integritetspolicy
          </a>
        </p>
        <p className="popup_title">Syften</p>
        <div className="popup-actions">
          <div
            style={{
              marginBottom: "15px",
            }}
          >
            {options.map((option) => {
              const isSelected = acceptedValues.includes(option);
              const isForced = forcedOptions.includes(option);
              const onChange = () => {
                if (isSelected) {
                  setAcceptedValues(acceptedValues.filter((v) => v !== option));
                } else {
                  setAcceptedValues([...acceptedValues, option]);
                }
              };
              console.log(`option: ${option}, isSelected: ${isSelected}`);

              return (
                <div
                  key={option}
                  style={
                    isForced
                      ? { opacity: 0.5, marginBottom: "10px" }
                      : { marginBottom: "10px" }
                  }
                >
                  <input
                    type="checkbox"
                    id={option}
                    name={option}
                    checked={isSelected}
                    onChange={isForced ? () => {} : onChange}
                  />
                  <label htmlFor={option}>{option}</label>
                </div>
              );
            })}
          </div>
          <WoofRoundedButton
            text={"Skicka"}
            link={copyParamsToNewLink("/")}
            onClick={() => {
              onClose(acceptedValues);
            }}
            grey_rounded={true}
          />
        </div>
        <button className="popup-close" onClick={() => onClose()}>
          ×
        </button>
      </div>
    </div>
  );
}
