import { route_categories, routes } from "../App";
import {
  FOOTER_NAVIGATION_CLICK,
  LogEvent,
  copyParamsToNewLink,
} from "../services/analytics";
import "./footer.css";

function LogNavigationClick(route) {
  LogEvent(FOOTER_NAVIGATION_CLICK, {
    link: route,
  });
}

export default function Footer() {
  return (
    <footer>
      <img
        className="Woof-logo"
        alt="woof_logo_white"
        src={require("../assets/woof_logo_white_resized.webp")}
      ></img>
      <div className="Routes-category-container">
        {route_categories.map((category) => {
          const index = route_categories.indexOf(category);

          return <RouteCategory category={category} key={index} />;
        })}
      </div>
      <div className="Divider"></div>
      <div className="Policies">
        <a
          className="Policy-link"
          href={copyParamsToNewLink("/anvandarvillkor")}
          onClick={() => LogNavigationClick("/anvandarvillkor")}
        >
          Användarvillkor
        </a>
        <p className="Separator-dot">·</p>
        <a
          className="Policy-link"
          href={copyParamsToNewLink("/integritetspolicy")}
          onClick={() => LogNavigationClick("/integritetspolicy")}
        >
          Integritetspolicy
        </a>
      </div>
      <p className="Woof-Legal-info">
        Woof ägs och drivs av Dogi AB (559299-3959), Brahegatan 3, Stockholm
      </p>
    </footer>
  );
}

function RouteCategory(props) {
  const category = props.category;

  const relevant_routes = routes.filter((route) => route.category === category);
  return (
    <div className="Routes-category">
      <p className="Route-category-name">{category}</p>
      {relevant_routes.map((route) => {
        const index = relevant_routes.indexOf(route);
        return <RouteLink route={route} key={index} />;
      })}
    </div>
  );
}

function RouteLink(props) {
  const route = props.route;

  if (route.path.includes("http")) {
    return (
      <a
        onClick={() => LogNavigationClick(route.path)}
        className="Route-link"
        href={copyParamsToNewLink(route.path)}
        target="_blank"
        rel="noreferrer"
      >
        {route.name}
      </a>
    );
  }

  return (
    <a
      className="Route-link"
      href={copyParamsToNewLink(route.path)}
      onClick={() => LogNavigationClick(route.path)}
    >
      {route.name}
    </a>
  );
}
