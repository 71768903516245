import "./header.css";
import { useState } from "react";

import Hamburger from "hamburger-react";
import { route_categories, routes } from "../App";
import WoofRoundedButton from "./rounded_woof_button";
import {
  APP_INSTALL_CLICK,
  HEADER_NAVIGATION_CLICK,
  LogEvent,
  copyParamsToNewLink,
} from "../services/analytics";
import { GlobalStateContext } from "../services/state_provider";

const arrow_down_svg =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M12,15.5a1.993,1.993,0,0,1-1.414-.585L5.293,9.621,6.707,8.207,12,13.5l5.293-5.293,1.414,1.414-5.293,5.293A1.993,1.993,0,0,1,12,15.5Z"/></g></svg>';

const arrow_up_svg =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M17.293,15.207,12,9.914,6.707,15.207,5.293,13.793,10.586,8.5a2,2,0,0,1,2.828,0l5.293,5.293Z"/></g></svg>';

function LogInstallClick() {
  console.log("Logging install click");
  LogEvent(APP_INSTALL_CLICK, {
    where: "HEADER",
  });
}

export default function Header(props) {
  const { install_link, isPhone, gclid } = props;

  const [isOpen, setOpen] = useState(false);

  function shouldExcludeFromServicesSubMenu(route) {
    return route.category === "Kom igång" && route.excludeFromSubmenu !== true;
  }

  const services_routes = routes.filter((route) =>
    shouldExcludeFromServicesSubMenu(route)
  );

  const other_routes = routes.filter(
    (route) => !shouldExcludeFromServicesSubMenu(route)
  );

  const to_return_routes = [
    {
      category: "Tjänster",
      items: services_routes,
      is_category: true,
    },
    ...other_routes,
  ];

  to_return_routes.sort((a, b) => {
    if (a.name === "Hem") {
      return -1;
    }
    if (b.name === "Hem") {
      return 1;
    }
    return 0;
  });

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const logo = (
    <div className="Logo-wrapper">
      <a href={copyParamsToNewLink("/")}>
        <img
          src={require("../assets/woof_logo_resized.webp")}
          className="Logo"
          alt="logo"
        />
      </a>
    </div>
  );

  return (
    <div>
      <header className="Header">
        <div className="Main-header">
          {logo}
          {isPhone ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <WoofRoundedButton
                text={props.button_text ?? "Till appen"}
                link={install_link}
                grey_rounded={true}
                onClick={() => {
                  LogInstallClick(install_link, gclid);
                }}
              />
              <div className="Hamburger">
                <Hamburger
                  toggled={isOpen}
                  toggle={setOpen}
                  size={27}
                  aria-label="toggle menu"
                />
              </div>
            </div>
          ) : (
            <div className="Header-routes">
              {to_return_routes.map((route) => {
                const index = to_return_routes.indexOf(route);
                return (
                  <DesktopLink
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    route={route}
                    isDropdownVisible={isDropdownVisible}
                    key={index}
                    install_link={install_link}
                    gclid={gclid}
                  />
                );
              })}
            </div>
          )}
        </div>
        {isPhone ? (
          <MenuContent
            isOpen={isOpen}
            install_link={install_link}
            gclid={gclid}
          />
        ) : null}
      </header>
    </div>
  );
}

/* function LogInstallClick() {
  console.log("Logging install click");
  LogEvent("APP_INSTALL_CLICK", "CLICKED_APP_INSTALL_BUTTON", null, `HEADER`);
} */

function LogNavigationClick(route) {
  LogEvent(HEADER_NAVIGATION_CLICK, {
    link: route,
  });
}

function DesktopLink(props) {
  const is_category = props.route.is_category;
  const current_page = window.location.pathname;
  return is_category ? (
    <div
      className="Header-route"
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div className="Dropdown-link-row">
        <a
          className="Header-route-link"
          href={copyParamsToNewLink(current_page)}
        >
          {props.route.category}
          <img
            className="Dropdown-arrow"
            alt="Dropdown-arrow"
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              props.isDropdownVisible ? arrow_up_svg : arrow_down_svg
            )}`}
          ></img>
        </a>
      </div>
      <DesktopDropdown
        isDropdownVisible={props.isDropdownVisible}
        items={props.route.items}
        install_link={props.install_link}
        gclid={props.gclid}
      />
    </div>
  ) : (
    <div className="Header-route">
      {props.route.path.includes("http") ? (
        <a
          className="Header-route-link"
          href={copyParamsToNewLink(props.route.path)}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            LogNavigationClick(props.route.path);
          }}
        >
          {props.route.name}
        </a>
      ) : (
        <a
          className="Header-route-link"
          href={copyParamsToNewLink(props.route.path)}
          onClick={() => {
            LogNavigationClick(props.route.path);
          }}
        >
          {props.route.name}
        </a>
      )}
    </div>
  );
}
function DesktopDropdown(props) {
  if (!props.isDropdownVisible) {
    return null;
  }
  return (
    <div className="Desktop-dropdown">
      {props.items.map((item) => {
        const index = props.items.indexOf(item);
        const is_last = index === props.items.length - 1;
        return (
          <a
            onClick={() => {
              LogNavigationClick(item.path);
            }}
            className="Header-route-link"
            href={copyParamsToNewLink(item.path)}
            key={index}
            style={{ paddingBottom: is_last ? "0" : "30px" }}
          >
            {item.name}
          </a>
        );
      })}
    </div>
  );
}
function MenuContent(props) {
  if (props.isOpen) {
    return (
      <div>
        <div className="Expanded-header-overlay"></div>
        <div className="Expanded-header-menu-container">
          <div className="Expanded-header-menu">
            {route_categories.map((category, category_index) => {
              const relevant_routes = routes.filter(
                (route) => route.category === category
              );
              return (
                <div className="Header-route-category" key={category_index}>
                  <p className="Header-route-category-name">{category}</p>
                  {relevant_routes.map((route, link_index) => {
                    if (route.path.includes("http")) {
                      return (
                        <a
                          className="Header-route-link"
                          href={copyParamsToNewLink(route.path)}
                          key={link_index}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {route.name}
                        </a>
                      );
                    }

                    return (
                      <a
                        className="Header-route-link"
                        href={copyParamsToNewLink(route.path)}
                        key={link_index}
                        onClick={() => LogNavigationClick(route.path)}
                      >
                        {route.name}
                      </a>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
}
