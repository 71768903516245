import { LogEvent } from "../services/analytics";
import WoofRoundedButton from "./rounded_woof_button";

function LogInstallClick() {
  console.log("Logging install click");

  LogEvent("APP_INSTALL_CLICK", {
    where: "WE_HAVE_HELPED_HUNDREDS_SECTION",
  });
}

export default function GreyTextSection(props) {
  const install_link = props.install_link;
  const isPhone = props.isPhone;

  if (!isPhone) return <div></div>;
  return (
    <div
      style={{
        backgroundColor: "#F5F7F8",

        marginBottom: "30px",
        padding: "20px",
      }}
    >
      <h2 style={{ fontSize: "18px" }}>
        Vi har hjälpt hundratals hundägare hitta pålitliga hundvakter
      </h2>
      <p style={{ marginBottom: "15px", marginTop: "15px" }}>
        Woof har hjälpt hundratals hundägare som dig att hitta pålitliga
        hundvakter, eller "Woofers" som vi kallar dem. Med Woof kan du få hjälp
        på begäran eller boka i förtid, det är upp till dig. Alla hundvakter
        registrerade på Woof är BankID-verifierade och genomgår en noggrann
        bakgrundskontroll för att garantera en så trygg miljö för din hund som
        möjligt.
      </p>
      <WoofRoundedButton
        text={"Hitta hundvakt"}
        link={install_link}
        onClick={LogInstallClick}
      />
    </div>
  );
}
