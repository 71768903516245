import "./terms.css";

export default function TermsLayout(props) {
  const sections = props.sections;
  return (
    <div className="Terms-body">
      <div className="Terms-title-area">
        <h1 className="Terms-title">{props.title}</h1>
        <p>Gäller från och med 2024-01-31 tills vidare</p>
      </div>
      {sections.map((section, index) => {
        if (section.divider)
          return <div className="Terms-Divider" key={index}></div>;
        if (section.curseive_title)
          return (
            <p className="Curseive-title" key={index}>
              {section.curseive_title}
            </p>
          );

        if (section.type === "table") {
          return (
            <div>
              {section.title != null ? (
                <p className="Terms-section-title-table">{section.title}</p>
              ) : null}{" "}
              <div className="Terms-table">
                <div className="key-row-container">
                  <div className="Table-top-row">
                    {section.columns.map((column, index) => {
                      return <div key={index}>{column}</div>;
                    })}
                  </div>
                </div>

                {section.rows.map((row, index) => {
                  return (
                    <div className="value-row-container">
                      <div className="Table-data-row">
                        {row.items.map((item, item_index) => {
                          const splitted_with_· = item.split("·");

                          if (splitted_with_·.length > 1) {
                            console.log("SPLITTED WITH DOT");
                            return (
                              <div className="Table-row" key={index}>
                                {splitted_with_·.map((splitted_item, index) => {
                                  return (
                                    <div>
                                      <p> {`· ${splitted_item}`}</p>
                                      <br />
                                      <br />
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }
                          return (
                            <p className="Table-row" key={index}>
                              {item}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }

        return (
          <TermsSection
            section={section}
            index={index}
            key={index}
            sections={sections}
          />
        );
      })}
    </div>
  );
} /*  */

function TermsSection(props) {
  const section = props.section;
  const index = props.index;

  return (
    <div key={index} className="Terms-section">
      <p className="Terms-section-title">{section.title}</p>
      {section.items.map((item, section_item_index) => {
        return (
          <TermsSubSection
            item={item}
            section={section}
            section_item_index={section_item_index}
            key={section_item_index}
            sections={props.sections}
          />
        );
      })}
    </div>
  );
}

function TermsSubSection(props) {
  const only_text_sections = props.sections.filter(
    (section) => section.type === "text"
  );
  const section = props.section;
  const item = props.item;
  const section_item_index = props.section_item_index;
  const text_index = only_text_sections.indexOf(section);

  if (typeof item === "string")
    return (
      <div className="Sub-section" style={{ display: "flex" }}>
        {section.items.length === 1 ? null : (
          <p className="Section_number">{`${text_index + 1}.${
            section_item_index + 1
          }`}</p>
        )}
        <p className="Terms-section-item" key={section_item_index}>
          {item}
        </p>
      </div>
    );

  return (
    <div>
      <div className="Sub-section-subtitle" style={{ display: "flex" }}>
        <p className="Section_number">{`${text_index + 1}.${
          section_item_index + 1
        }`}</p>

        <p className="Terms-section-subtitle" key={section_item_index}>
          {item.subtitle}
        </p>
      </div>
      {item.items.map((sub_item, sub_item_index) => {
        return (
          <div
            className="Sub-section"
            style={{ display: "flex" }}
            key={sub_item_index}
          >
            <p className="Section_number">{`${text_index + 1}.${
              section_item_index + 1
            }.${sub_item_index + 1}`}</p>
            <p className="Terms-section-item" key={sub_item_index}>
              {sub_item}
            </p>
          </div>
        );
      })}
    </div>
  );
}

/*    */
