import {
  CONTENT_STATE,
  ERROR_STATE,
  LOADING_STATE,
  initialAddress,
} from "../services/state_provider";
import WoofAutocompleteField from "../components/auto_complete_field";
import { Shimmer } from "react-shimmer";
import "../App.css";
import {
  copyParamsToNewLink,
  LogEvent,
  VIEW_WOOFER_PROFILE,
} from "../services/analytics";

export default function ExploreWoofersScreen(props) {
  const { woofers, address, setAddress, woofersState } = props;

  /*   const inputRef = useRef();

  const autoComplete = new window.google.maps.places.Autocomplete(
    inputRef.current
  ); */

  return (
    <div
      style={{
        marginTop: "70px",
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <WoofAutocompleteField
        address={address}
        setAddress={setAddress}
        initialAddress={initialAddress}
      />
      <GetWoofersContent woofersState={woofersState} woofers={woofers} />
    </div>
  );
}

export function GetWoofersContent(props) {
  const { woofersState, woofers, shimmers, max } = props;
  switch (woofersState) {
    case LOADING_STATE: {
      return <WoofersLoading shimmers={shimmers} />;
    }
    case CONTENT_STATE: {
      return <ContentView woofers={woofers} max={max} />;
    }
    case ERROR_STATE: {
      return <div></div>;
    }
  }
}

export function WoofersLoading(props) {
  const { shimmers } = props;

  let list = [];

  for (let i = 0; i < (shimmers ?? 6); i++) {
    list.push(i);
  }

  return (
    <div>
      {list.map((item) => (
        <div style={{ paddingBottom: "15px" }} key={item}>
          <Shimmer width={"100%"} height={150} />
        </div>
      ))}
    </div>
  );
}

function ContentView(props) {
  const { woofers, max } = props;
  let list = null;
  if (max) {
    list = woofers.slice(0, max);
  } else {
    list = woofers;
  }
  return (
    <div className="Woofers-List">
      {list.map((woofer) => {
        return (
          <a
            href={copyParamsToNewLink(`/woofer/?woofer_id=${woofer.id}`)}
            onClick={() => {
              LogEvent(VIEW_WOOFER_PROFILE, {
                where: "WOOFER_SCREEN",
                wooferId: woofer.id,
              });
            }}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <div
              style={{
                boxShadow: "0px 1px 10px 1px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",

                display: "flex",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "15px",
                paddingRight: "15px",

                marginBottom: "20px",
                backgroundColor: "white",
              }}
              key={woofer.id}
            >
              <img
                src={woofer.profileImage}
                alt={woofer.objectID}
                style={{
                  marginRight: "15px",
                  height: "50px",
                  width: "50px",
                  borderRadius: "100%",
                }}
              ></img>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "16px",
                    margin: "0",
                    padding: "0",

                    color: "#2c2044",
                  }}
                >
                  {`${woofer.first_name.trim()}, ${woofer.age()} år`}
                </p>

                <p
                  style={{
                    ...greySmallStyle,
                    paddingBottom: "5px",
                    paddingTop: "5px",
                  }}
                >
                  {`${Math.floor(woofer.distance).toFixed(1)} km`}
                </p>

                <WooferReviewText woofer={woofer} />

                <p
                  style={{
                    ...greySmallStyle,

                    paddingBottom: "8px",
                    paddingTop: "8px",
                  }}
                >
                  {woofer.description.length > 100
                    ? woofer.description.substring(0, 100) + "..."
                    : woofer.description}
                </p>
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
}

const starIconSvg = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.73462 3.57428L6.66046 3.104L5.28255 0.16462C5.17963 -0.0548734 4.82005 -0.0548734 4.71713 0.16462L3.33964 3.104L0.265478 3.57428C0.0129788 3.61309 -0.0878543 3.92063 0.0892286 4.10215L2.32256 6.39473L1.79464 9.63581C1.75256 9.89327 2.02714 10.0865 2.25422 9.96004L5.00005 8.44028L7.74588 9.96046C7.97087 10.0856 8.24796 9.89619 8.20546 9.63622L7.67754 6.39515L9.91087 4.10257C10.088 3.92063 9.9867 3.61309 9.73462 3.57428Z" fill="#826CAE"/>
</svg>
`;

export function WooferReviewText(props) {
  const { woofer } = props;
  if (woofer.avg && woofer.numberOfReviews) {
    return (
      <div style={{ display: "flex", ...greySmallStyle }}>
        <img
          alt={"star-icon"}
          src={`data:image/svg+xml;utf8,${encodeURIComponent(starIconSvg)}`}
          style={{
            paddingRight: "5px",
          }}
        ></img>
        <p> {woofer.avg.toFixed(1)}</p>
        <p style={{ paddingLeft: "5px", paddingRight: "5px" }}>·</p>
        <p>
          {" "}
          {woofer.numberOfReviews}{" "}
          {woofer.numberOfReviews === 1 ? "omdöme" : "omdömen"}
        </p>
      </div>
    );
  }
  return (
    <div style={{ display: "flex", ...greySmallStyle }}>
      <p>Ny hundvakt</p>
    </div>
  );
}

export const greySmallStyle = {
  fontFamily: "Source Sans 3",
  fontWeight: 400,
  margin: 0,
  padding: 0,
  color: "#717070",
};

/* font-family: "Source Sans 3", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  
  
  so we can use it in js:

  {
  fontFamily: "Source Sans 3",
  fontWeight: 400,
  margin: 0,
  padding: 0
  }
  */
