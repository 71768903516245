import HeroSection from "../components/hero_section";
import HowItWorksSection from "../components/how_it_works_section";
import { Helmet } from "react-helmet-async";

import {
  FollowLocationLive,
  VerificationAndReview,
  YouChooseWho,
} from "./safety";
import ChecklistSection from "../components/checklist_section";
import { WhatDoesItCost } from "./payment_info";
import FaqQuestionSection from "../components/faq_question_section";
import { getRelevantFaqQuestionsForOwners } from "./home";
import ReviewsSection from "../components/reviews_section";

import PopularNearbyWoofers from "../components/popular_nearby_woofers";

export default function DogWalkingScreen(props) {
  const { install_link, isPhone, woofers, address, setAddress, woofersState } =
    props;

  return (
    <div className="Body">
      <Helmet>
        <title>
          Få hjälp med hundrastning | Hitta trygga hundrastare nära dig
        </title>
        <meta
          name="description"
          content="Woof gör det enkelt och tryggt att hitta hundrastare som kan hjälpa dig med hundrastning. Få hjälp direkt eller förboka."
        />
      </Helmet>
      <HeroSection
        is_phone={isPhone}
        install_link={install_link}
        mobile_image={require("../assets/promenad_hero.webp")}
        mobile_title="Få trygg hjälp med hundrastning"
        desktop_title="Få trygg hjälp med hundrastning"
        desktop_image={require("../assets/desktop_hero_promenad.webp")}
        desktop_subtitle="Woof hjälper dig hitta hundvakt på ett tryggt och smidigt sätt. Alla
        hundvakter är BankID-verifierade och du kan följa din hunds plats i
        realtid i appen."
        button_text={"Ladda ner appen"}
        alt_text="En hund som väntar på att gå ut"
      />

      <PopularNearbyWoofers
        address={address}
        woofers={woofers}
        setAddress={setAddress}
        woofersState={woofersState}
      />
      <FollowLocationLive isPhone={isPhone} is_grey={false} />
      <HowItWorksSection install_link={install_link} is_phone={isPhone} />
      <VerificationAndReview isPhone={isPhone} is_grey={true} />
      <ChecklistSection
        install_link={install_link}
        title="Woof – ett hundrastningsval som är pålitligt och effektivt."
        checklist={checklist}
        isPhone={isPhone}
      />
      <YouChooseWho
        install_link={install_link}
        isPhone={isPhone}
        is_grey={false}
      />
      <ReviewsSection isPhone={isPhone} />
      <WhatDoesItCost isPhone={isPhone} />
      <FaqQuestionSection
        isPhone={isPhone}
        questions={getRelevantFaqQuestionsForOwners()}
      />
    </div>
  );
}

const checklist = [
  {
    title: "För hundar i alla storlekar",
    subtitle:
      "Oavsett om din hund är stor eller liten, gammal eller ung, så försöker Woof hjälpa dig hitta en hundrastare som passar just din hund.",
  },
  {
    title: "Flexibla tider",
    subtitle:
      "Hitta en hundrastare som kan hjälpa dig med hundrastning när du behöver det.",
  },
  {
    title: "BankID-verifierade hundrastare",
    subtitle:
      "Alla hundrastare som är anslutna till Woof är verifierade med BankID.",
  },
  {
    title: "Prisvärd hundrastning",
    subtitle:
      "Olika hundrastare tar olika betalt för att hjälpa till med hundrastning. Du och hundrastaren kommer överens om priset tillsammans.",
  },
  {
    title: "Håll dig uppdaterad",
    subtitle:
      "Följ hundens promenad på kartan och få fotouppdateringar från hundrastaren under promenaden.",
  },
  {
    title: "Du väljer plats",
    subtitle:
      "Välj var hundrastningen ska ske, till exempel i en park eller i skogen.",
  },
  {
    title: "Recensioner",
    subtitle:
      "Läs recensioner från andra hundägare innan du bokar en hundrastning.",
  },
];
