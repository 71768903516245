import React from 'react';
import { why_woof_bullets } from "../App";
import "./woof_bullet.css";

export default function WoofBullet(props) {
  const is_last = props.index === why_woof_bullets.length - 1;
  const fillColor = props.color || "#2c2044"; // Default color if not provided
  const svgWidth = props.size || 17; // Default size if not provided
  const svgHeight = (svgWidth * 13) / 17; // Maintain aspect ratio based on original size

  const padding = props.padding ?? 5


  // Updated SVG string to use CSS variables for dynamic styling
  const check_svg = `
    <svg width="${svgWidth}" height="${svgHeight}" viewBox="0 0 310 292" fill="${fillColor}" xmlns="http://www.w3.org/2000/svg">
      <path d="M72.0683 267.492C56.9267 250.48 44.6956 233.84 32.8804 217.725C22.0841 203.072 12.7228 187.413 4.92595 170.967C0.0459476 160.987 -2.11405 150.883 8.45635 144.386C38.75 125.769 44.958 143.545 61.4236 164.171C71.1836 176.392 85.5756 196.546 94.5932 209.279C103.207 221.439 113.708 199.492 117.9 193.299C132.799 171.291 171.523 117.409 187.525 97.4923C202.717 78.5851 251.753 25.6171 260.894 17.1923C268.675 10.0187 285.103 -5.71571 296.15 2.96429C307.759 12.0843 313.43 29.4203 305.27 40.6923C291.059 60.3163 268.736 78.9843 253.291 97.6699C222.025 135.494 192.424 176.265 164.011 216.86C154.356 230.655 140.8 253.76 132.384 268.721C116.64 296.709 102.812 302.036 72.0683 267.492Z"/>
    </svg>`;

  let style = {
    marginBottom: props.no_padding ? "0" : is_last ? "0" : "15px",
  };

  return (
    <div className="Woof-bullet" style={style}>
      {props.emoji != null ? (
        <p className="Emoji">{props.emoji}</p>
      ) : (
        <img
          className="Check-svg"
          alt="check-svg"
          style={{ width: `${svgWidth}px`, height: `${svgHeight}px`,  paddingRight: `${padding}px`, paddingTop: "2px"}}
          src={`data:image/svg+xml;utf8,${encodeURIComponent(check_svg)}`}
        />
      )}
      <p>{props.text}</p>
    </div>
  );
}