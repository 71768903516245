import { LogEvent } from "../services/analytics";
import "./how_it_works_section.css";
import WoofRoundedButton from "./rounded_woof_button";

function LogInstallClick() {
  console.log("Logging install click");

  LogEvent("APP_INSTALL_CLICK", {
    where: "HOW_IT_WORKS_SECTION",
  });
}

export default function HowItWorksSection(props) {
  const is_phone = props.is_phone;

  return (
    <div className="How-it-works">
      <div style={{ paddingTop: "30px", paddingLeft:"20px", paddingRight:"20px" }}>
        <h2 className="How-it-works-title">
          {props.title ?? "Hur funkar det?"}
        </h2>
        <HowItWorksCards
          list={props.list ?? how_it_works_owner_bullets}
          is_phone={is_phone}
        />
        {
          <div className="How-it-works-woof-button">
            <WoofRoundedButton
              onClick={() => {
                LogInstallClick();
              }}
              text={props.button_text ?? "Kom igång"}
              link={props.install_link}
            />
          </div>
        }
      </div>
    </div>
  );
}
function HowItWorksCards(props) {
  return (
    <div>
      {props.list.map((bullet, index) => {
        return (
          <div
            className="How-it-works-card"
            style={{
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#f5f5f5",
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            }}
          >
            <p style={{ fontSize: "24px" }}>{bullet.emoji}</p>
            <p
              style={{
                fontFamily: "Montserrat",

                fontWeight: "600",
                fontSize: "16px",
                margin: "0",
                padding: "0",
                color: "#2c2044",
                paddingBottom: "15px",
                paddingTop: "15px",
              }}
            >
              {`${index + 1}. ${bullet.title}`}
            </p>
            <p>{bullet.subtitle}</p>
          </div>
        );
      })}
    </div>
  );
}

/* function HowItWorksBullet(props) {
  const is_phone = props.is_phone;
  const index = props.index;
  const bullet = props.bullet;

  const subtitle_splitted_by_newline = bullet.subtitle.split("\n");

  return (
    <div
      className="How-it-works-bullet"
      style={{
        marginBottom: !is_phone
          ? 0
          : index === how_it_works_owner_bullets.length - 1
          ? "0"
          : "20px",
      }}
    >
      <div className="How-it-works-bullet-text-section">
        <p>{bullet.emoji}</p>
        <h3 className="How-it-works-bullet-title">{bullet.title}</h3>
        {bullet.subtitle &&
          subtitle_splitted_by_newline.map((splitted, index) => {
            return (
              <p key={index} className="How-it-works-bullet-subtitle">
                {splitted}
              </p>
            );
          })}
      </div>
      {bullet.image != null ? (
        <img
          src={bullet.image}
          alt={bullet.alt_text}
          className="How-it-works-bullet-image"
        />
      ) : (
        <div className="No-image-padding"></div>
      )}
    </div>
  );
} */

/* how_it_works_resized_1 */

export const how_it_works_owner_bullets = [
  {
    title: "Skapa en förfrågan",
    subtitle:
      "Skapa en förfrågan gratis. Lägg till din hund, välj tjänst, plats och tid och annan information.",
    alt_text: "Skapa en förfrågan",
    emoji: "📋",
  },
  {
    title: "Ta emot ansökningar",
    subtitle:
      "Verifierade hundvakter i ditt närområde ansöker till din förfrågan och föreslår ett pris",

    image: require("../assets/how_it_works_resized_3.webp"),
    alt_text: "Ansökningar från hundvakter",
    emoji: "💜",
  },
  {
    title: "Välj hundvakt",
    subtitle:
      "Välj den hundvakt som passar dig bäst och bekräfta hundvakten till uppdraget. ",
    image: require("../assets/how_it_works_resized_4.webp"),
    emoji: "✅",
  },
];
