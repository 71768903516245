import ReactGA from "react-ga4";

export const APP_INSTALL_CLICK = "APP_INSTALL_CLICK";
export const NAVIGATION_CLICK = "NAVIGATION_CLICK";
export const SERVICE_CARD_NAVIGATION_CLICK = "SERVICE_CARD_NAVIGATION_CLICK";
export const HEADER_NAVIGATION_CLICK = "HEADER_NAVIGATION_CLICK";
export const FOOTER_NAVIGATION_CLICK = "FOOTER_NAVIGATION_CLICK";
export const ACCEPT_COOKIES = "ACCEPT_COOKIES";
export const VIEW_WOOFER_PROFILE = "VIEW_WOOFER_PROFILE";

export function copyParamsToNewLink(link) {
  if (link.includes("intercom.help")) {
    return link;
  }
  if (link.includes("woofapp.app.link")) {
    return link;
  }
  const branchLinkId = localStorage.getItem("branchLinkId");
  const gclid = localStorage.getItem("gclid");

  let toReturn = link;

  console.log(
    `appending queryy params branchLinkId: ${branchLinkId}, gclid: ${gclid}`
  );
  if (branchLinkId) {
    if (toReturn.includes("?")) {
      toReturn += `&branchLinkId=${branchLinkId}`;
    } else {
      toReturn += `?branchLinkId=${branchLinkId}`; //BRANCHLINKID NOT BEING COPIED ON MY PHONE! WHY??
    }
  }

  if (gclid) {
    if (toReturn.includes("?")) {
      toReturn += `&gclid=${gclid}`;
    } else {
      toReturn += `?gclid=${gclid}`;
    }
  }

  console.log(`replace link with: ${toReturn}`);

  return toReturn;
}

export function LogEvent(event_name, args = {}) {
  const branchLinkId = localStorage.getItem("branchLinkId");

  const gclid = localStorage.getItem("gclid");
  try {
    const isLive = window.location.hostname.includes("getwoof");

    let parames = {
      ...args,
      debug: !isLive,
    };

    if (branchLinkId) {
      console.log("branchLinkId", branchLinkId);
      parames.branchLinkId = branchLinkId;
    }

    if (gclid) {
      parames.gclid = gclid;
      parames.gclid_just_for_us = gclid;
    }

    console.log(`LOGGING EVENT, params: ${JSON.stringify(parames)}`);

    ReactGA.event(event_name, parames);

    if (event_name === APP_INSTALL_CLICK) {
      trackConversion();
    }
  } catch (err) {
    console.log(`error in LogEvent: ${err}`);
  }
}

function trackConversion() {
  console.log("Tracking conversion too google ads");
  try {
    if (typeof window.gtag === "function") {
      window.gtag("event", "conversion", {
        send_to: "AW-10891815645/fAufCMbMhMQZEN3Vz8ko",
      });
      console.log("Conversion tracked");
    } else {
      console.error("gtag is not defined");
    }
  } catch (err) {
    console.log(`error in trackConversion: ${err}`);
  }
}

export function setConsent(consent, isDefault) {
  try {
    if (typeof window.gtag === "function") {
      console.log(`Setting consent to ${consent} inside gtag`);
      window.gtag("consent", isDefault ? "default" : "update", {
        ad_storage: consent,
        ad_user_data: consent,
        ad_personalization: consent,
        analytics_storage: consent,
      });
    } else {
      console.error("gtag is not defined :((((");
    }
  } catch (err) {
    console.log(`error in setConsent: ${err}`);
  }
}

export function setUserAnalyticsParams(data) {
  ReactGA.set(data);
}
/* 

analytics={{
       category: "NAVIGATION_CLICK",
       action: "CLICKED_LEARN_MORE",
       value: null,
       label: `INFO_SECTION_HOW_HOW_MUCH_DOES_WALKING_COST`,
     }}

analytics={{
       category: "APP_INSTALL_CLICK",
       action: "CLICKED_APP_INSTALL_BUTTON",
       value: null,
       label: `INFO_SECTION_SITTING_SAFETY`,
     }}


function logInstallClick() {
console.log("Logging install click");
LogEvent(
 "APP_INSTALL_CLICK",
 "CLICKED_APP_INSTALL_BUTTON",
 null,
 `HERO_SECTION`
);
}


LogEvent(
 analytics.category,
 analytics.action,
 analytics.value,
 analytics.label,
 false
);
*/
