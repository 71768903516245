import { getParameterByName } from "../services/state_provider";
import "../App.css";
import { greySmallStyle, WooferReviewText } from "./explore_woofers";
import WoofBullet from "../components/woof_bullet";
import WoofRoundedButton from "../components/rounded_woof_button";
import { useEffect, useState } from "react";
import { Shimmer } from "react-shimmer";
import { ShowMoreOrLessButton } from "../components/faq_question_section";
import { VerificationAndReview } from "./safety";
import { APP_INSTALL_CLICK, LogEvent } from "../services/analytics";

export default function WooferScreen(props) {
  const { woofers, isPhone } = props;
  const wooferId = getParameterByName("woofer_id");
  const [experienceTextExpanded, setExperienceTextExpanded] = useState(false);
  const [wooferLink, setWooferLink] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [showAllReviews, setShowAllReviews] = useState(false);

  const gclid = localStorage.getItem("gclid");

  useEffect(() => {
    // This code runs only once when the component is mounted.
    // Perform initialization here, such as fetching data or setting up subscriptions.

    const fetchData = async () => {
      let branchLinkId = localStorage.getItem("branchLinkId");

      const paramsString = `?wooferId=${wooferId}&branchLinkId=${branchLinkId}&gclid=${gclid}`;

      console.log(`paramsString: ${paramsString}`);

      /*     const { wooferId, channel, branchLinkId } = req;
       */
      const url = `https://europe-west1-woof-react-landing.cloudfunctions.net/getWoofersReviewsAndInstallLink/${paramsString}`;

      console.log(`fetching data from: ${url}`);
      console.log(`fetching with branchLinkId: ${branchLinkId}`);

      const response = await fetch(url);

      const data = await response.json();

      const { createdLink, reviews } = data.data;

      console.log("Response from wooferLink and reviews", data);

      setWooferLink(createdLink);

      const toSetReviews = reviews
        .map((review) => new WooferReview(review))
        .filter(
          (review) =>
            review.enteredReview !== null && review.enteredReview.trim() !== ""
        );

      toSetReviews.sort((a, b) => {
        return new Date(b.time) - new Date(a.time);
      });

      setReviews(toSetReviews);
    };

    fetchData();

    /*  fetchData(); */

    // Clean up function (optional)
    return () => {
      // Clean up any subscriptions or resources when the component is unmounted
    };
  }, []); // Empty dependency array ensures this effect runs only once

  let woofer = null;

  if (wooferId) {
    console.log(`woofersLength: ${woofers.length}`);
    for (const w of woofers) {
      if (w.id === wooferId) {
        console.log(`found woofer: ${w.first_name}`);
        woofer = w;
        break;
      }
    }

    console.log(`woofer: ${woofer}`);

    if (!woofer) return <div></div>;

    const hasMoreReviews = reviews && reviews.length > 3;
    const hasNoReviews = reviews && reviews.length === 0;

    const showExperienceSection =
      woofer.experienceBullets || woofer.experiencetext;

    const showHomeBullets = woofer.homeBullets != null;
    const showWhyWoofer = woofer.whyWooferText != null;
    return (
      <div>
        <div
          style={{
            paddingTop: "70px",
          }}
        >
          <RenderImage woofer={woofer} />
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "55px",
            }}
          >
            <div
              style={{
                paddingBottom: "20px",
              }}
            >
              <h2
                style={{
                  paddingBottom: "5px",
                }}
              >
                {woofer.first_name}
              </h2>
              <WooferReviewText woofer={woofer} />
            </div>

            <TitleAndTextSection
              text={woofer.description}
              title={"Om"}
              showAllText={true}
              hideMoreOrLess={true}
              hideDivider={
                hasNoReviews &&
                !showExperienceSection &&
                !showHomeBullets &&
                !showWhyWoofer
              }
            />
            {hasNoReviews ? (
              <div></div>
            ) : (
              <TitleAndTextSection
                title={"Omdömen"}
                reviews={
                  !reviews
                    ? null
                    : showAllReviews || reviews.length < 3
                    ? reviews
                    : reviews.slice(0, 3)
                }
                isLoadingReviews={!reviews}
                showMore={hasMoreReviews}
                isShowingMore={showAllReviews}
                showMoreOrLessCallback={setShowAllReviews}
                hideDivider={
                  !showExperienceSection && !showHomeBullets && !showWhyWoofer
                }
              />
            )}

            {showExperienceSection ? (
              <TitleAndTextSection
                title={"Erfarenhet"}
                bullets={woofer.experienceBullets}
                text={woofer.experiencetext}
                showAllText={experienceTextExpanded}
                showAllTextCallback={setExperienceTextExpanded}
                hideDivider={!showHomeBullets && !showWhyWoofer}
              />
            ) : (
              <div></div>
            )}
            {showHomeBullets ? (
              <TitleAndTextSection
                title={"Hem"}
                bullets={woofer.homeBullets}
                hideDivider={!showWhyWoofer}
              />
            ) : (
              <div></div>
            )}

            {woofer.whyWooferText ? (
              <TitleAndTextSection
                title={`Varför ${woofer.first_name} är en bra hundvakt`}
                text={woofer.whyWooferText}
                hideDivider={true}
              />
            ) : (
              <div></div>
            )}
          </div>
          {wooferLink && (
            <div
              style={{
                position: "sticky",

                bottom: "0",

                padding: "20px",
                backgroundColor: "white",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
              }}
            >
              <h3
                style={{
                  paddingBottom: "15px",
                }}
              >
                Hämta appen för att komma i kontakt med {woofer.first_name}
              </h3>
              <WoofRoundedButton
                text="Till appen"
                link={wooferLink}
                onClick={() => {
                  LogEvent(APP_INSTALL_CLICK, {
                    where: "WOOFER_SCREEN",
                    wooferId: wooferId,
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function TitleAndTextSection(props) {
  const {
    title,
    text,
    bullets,
    showAllText,
    showAllTextCallback,
    hideMoreOrLess,
    reviews,
    isLoadingReviews,
    showMore,
    showMoreOrLessCallback,
    isShowingMore,
    hideDivider,
  } = props;

  let toReturnText = !text
    ? null
    : showAllText || text.length < 200
    ? text
    : `${text?.substring(0, 200)}`;
  return (
    <div key={title}>
      <h3
        style={{
          fontWeight: "700",
          paddingBottom: "20px",
        }}
      >
        {title}
      </h3>
      {isLoadingReviews && <Shimmer width={"100%"} height={"100px"}></Shimmer>}
      {reviews && (
        <div>
          {reviews.map((review) => {
            const isLast = reviews.indexOf(review) === reviews.length - 1;
            return (
              <div
                key={review.reviewId}
                style={{
                  marginBottom: isLast ? "0" : "20px",
                  backgroundColor: "#f5f5f5",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img
                    src={review.ownerProfileImage}
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "100%",
                      marginRight: "10px",
                    }}
                    alt={review.reviewId}
                  ></img>
                  <div>
                    <p>{review.ownerName}</p>
                    <div
                      style={{
                        height: "3px",
                      }}
                    ></div>
                    <p
                      style={{
                        ...greySmallStyle,
                      }}
                    >
                      {review.time.toString()}
                    </p>
                  </div>
                </div>
                <p
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  {review.enteredReview}
                </p>
              </div>
            );
          })}
          {showMore && (
            <div
              style={{
                paddingTop: "10px",
              }}
            >
              <ShowMoreOrLessButton
                text={
                  isShowingMore ? "Visa färre omdömen" : "Visa fler omdömen"
                }
                callback={() => showMoreOrLessCallback(!isShowingMore)}
                show_all={isShowingMore}
              ></ShowMoreOrLessButton>
            </div>
          )}
        </div>
      )}

      {toReturnText ? (
        <div>
          <p
            style={{
              whiteSpace: "pre-wrap", // This will respect spaces and line breaks
            }}
          >
            {toReturnText}

            {hideMoreOrLess || !showAllTextCallback ? (
              <div></div>
            ) : (
              <span
                onClick={() => {
                  showAllTextCallback(!showAllText);
                }}
                style={{
                  fontWeight: "600",
                }}
              >
                {!showAllText ? "   Läs mer" : "   Läs mindre"}
              </span>
            )}
          </p>
        </div>
      ) : (
        <div></div>
      )}
      {bullets && bullets.length > 0 ? (
        <div
          style={{
            paddingTop: toReturnText ? "25px" : "0",
          }}
        >
          {bullets.map((bullet) => {
            return (
              <div key={bullets.indexOf(bullet)}>
                <WoofBullet text={bullet.text} padding={0} />
              </div>
            );
          })}
        </div>
      ) : (
        <div></div>
      )}

      {!hideDivider ? (
        <div
          style={{
            marginTop: "30px",
            marginBottom: "30px",
            backgroundColor: "#f5f5f5",
            width: "100%",
            height: "2px",
          }}
        ></div>
      ) : (
        <div style={{
          height: "40px"
        }}></div>
      )}
    </div>
  );
}

function getImages(woofer) {
  if (woofer.images && woofer.images.length > 0) return woofer.images;
  return [woofer.profileImage];
}

function RenderImage(props) {
  const { woofer } = props;
  return (
    <div style={{ position: "relative" }}>
      <img
        style={{
          width: "100%",
          maxHeight: "300px",
          objectFit: "cover",
        }}
        alt={`Omslagsbilder från ${woofer.first_name}, verifierad hundvakt på Woof.`}
        src={getImages(woofer)[0]}
      ></img>
      <img
        src={woofer.profileImage}
        alt={`${woofer.first_name}, verifierad hundvakt på Woof`}
        style={{
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
          width: "90px",
          height: "90px",
          borderRadius: "100%",
          position: "absolute",
          bottom: "-45px", // Adjust as needed
          left: "20px", // Adjust as needed
          border: "3px solid white",
        }}
      ></img>
    </div>
  );
}

class WooferReview {
  constructor(json) {
    function convertToReadableDate(seconds, nanoseconds) {
      // Convert seconds to milliseconds
      const milliseconds = seconds * 1000;

      // Convert nanoseconds to milliseconds
      const additionalMilliseconds = nanoseconds / 1000000;

      // Combine milliseconds and additional milliseconds
      const totalMilliseconds = milliseconds + additionalMilliseconds;

      // Create a date object
      const date = new Date(totalMilliseconds);

      // Return the readable date string
      return date.toDateString();
    }
    this.enteredReview = json.enteredReview;
    this.ownerName = json.ownerModel.name;
    this.ownerProfileImage = json.ownerModel.profileImage;
    this.rating = json.rating;
    this.time = convertToReadableDate(
      json.time._seconds,
      json.time._nanoseconds
    );
    this.usedFallbackImage = json.usedFallbackImage;
    this.reviewId = json.reviewId;
  }
}
