export default function GetTheAppSection(props) {
 /*  const sidewayArrowSvg =
    '<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 6.1C0.502944 6.1 0.1 6.50294 0.1 7C0.1 7.49706 0.502944 7.9 1 7.9L1 6.1ZM14.6364 7.63639C14.9879 7.28492 14.9879 6.71507 14.6364 6.3636L8.90883 0.636038C8.55736 0.284567 7.98751 0.284567 7.63604 0.636039C7.28457 0.98751 7.28457 1.55736 7.63604 1.90883L12.7272 7L7.63604 12.0912C7.28457 12.4426 7.28457 13.0125 7.63604 13.364C7.98751 13.7154 8.55736 13.7154 8.90883 13.364L14.6364 7.63639ZM1 7.9L14 7.9L14 6.1L1 6.1L1 7.9Z" fill="#494452"/></svg>';
  const install_link = props.install_link; */
  const isPhone = props.isPhone;
  if (!isPhone) return <div></div>;
  return (
    <div></div>
   /*  <a href={install_link} style={{ textDecoration: "none", color: "inherit" }}>
      <div
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#F5F7F8",
        }}
      >
        <h4>Hämta appen och utforska 200+ hundvakter i din stad</h4>
        <div
          style={{
            display: "flex",
            alignItems: "center", // Added this line to center items vertically

            marginTop: "20px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            border: "1px solid #E5E5E5",
          }}
        >
          <img
            src={require("../assets/Woof-appicon-512.png")}
            style={{
              height: "80px",
              width: "80px",
              borderRadius: "10px",
              marginRight: "20px",
            }}
          ></img>
          <p
            style={{
              fontSize: "18px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
          >
            Ladda ner Woof-appen
          </p>
          <img
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              sidewayArrowSvg
            )}`}
            style={{ marginLeft: "auto" }}
          ></img>
        </div>
      </div>
    </a> */
  );
}
