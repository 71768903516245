import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
async function selectAddress(address, setAddress, initialAddress) {
  let geocoded = null;
  if (
    address !== null &&
    address !== undefined &&
    address.address !== initialAddress.address
  ) {
    geocoded = await geocodeByAddress(address.label).then((results) => {
      return getLatLng(results[0]);
    });
  }

  if (geocoded) {
    const toSet = {
      address: address.label,
      geoloc: geocoded,
    };

    setAddress(toSet);
  }
}
export default function WoofAutocompleteField(props) {
  const { address, setAddress, initialAddress } = props;
  console.log(`ADDRESS: ${JSON.stringify(address)}`);
  return (
    <div
      style={{
        marginBottom: "20px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
        borderRadius: "5px",
      }}
    >
      <GooglePlacesAutocomplete
        onLoadFailed={(error) =>
          console.error("Could not inject Google script", error)
        }
        selectProps={{
          address,
          onChange: (val) => selectAddress(val, setAddress, initialAddress),
          placeholder: address.address,
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["se"],
          },
        }}
      />
    </div>
  );
}
