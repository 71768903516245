import { copyParamsToNewLink } from "../services/analytics";

export default function PageNotFound(props) {
  return (
    <div className="NotFoundScreenBody">
      <h1>Hoppsan, det verkar som du gått vilse...</h1>
      <p className="NotFoundSubtitle">
        Vi kunde inte hitta den sida du letade efter. Tryck{" "}
        <a href={copyParamsToNewLink("/")}>här</a> för att komma tillbaka till
        hem.
      </p>
    </div>
  );
}
