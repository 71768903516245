import { Helmet } from "react-helmet-async";
import HeroSection from "../components/hero_section";
import HowItWorksSection from "../components/how_it_works_section";

import GreyTextSection from "../components/grey_text_section";
import GetTheAppSection from "../components/get_the_app_section";
import {
  FollowLocationLive,
  VerificationAndReview,
  YouChooseWho,
} from "./safety";
import ChecklistSection from "../components/checklist_section";
import FaqQuestionSection from "../components/faq_question_section";
import { getRelevantFaqQuestionsForOwners } from "./home";
import { WhatDoesItCost } from "./payment_info";
import ReviewsSection from "../components/reviews_section";
import PopularNearbyWoofers from "../components/popular_nearby_woofers";

export default function OvernightStay(props) {
  const { install_link, isPhone, woofers, address, setAddress, woofersState } =
    props;

  return (
    <div className="Body">
      <Helmet>
        <title>
          Få hjälp med övernattning | Hitta trygga hundpassare nära dig
        </title>
        <meta
          name="description"
          content="Glöm bort trånga hundpensionat. Med Woof hittar du hundpassare som erbjuder trygg och kärleksfull passning åt din vovve när du är borta."
        />
      </Helmet>
      <HeroSection
        is_phone={isPhone}
        install_link={install_link}
        mobile_image={require("../assets/overnattning_hero.webp")}
        mobile_title="Få trygg hjälp med övernattning"
        desktop_title="Få trygg hjälp med övernattning"
        desktop_image={require("../assets/overnattning-hero-desktop.webp")}
        desktop_subtitle="Woof hjälper dig hitta hundvakt på ett tryggt och smidigt sätt. Alla
        hundvakter är BankID-verifierade och du kan följa din hunds plats i
        realtid i appen."
        button_text={"Ladda ner appen"}
        alt_text="En glad retriever"
      />
      <PopularNearbyWoofers
        address={address}
        woofers={woofers}
        setAddress={setAddress}
        woofersState={woofersState}
      />
      <YouChooseWho
        install_link={install_link}
        isPhone={isPhone}
        is_grey={false}
      />
      <GreyTextSection install_link={install_link} is_phone={isPhone} />
      <HowItWorksSection install_link={install_link} is_phone={isPhone} />
      <GetTheAppSection install_link={install_link} is_phone={isPhone} />

      <VerificationAndReview isPhone={isPhone} is_grey={true} />
      <ChecklistSection
        install_link={install_link}
        title="Inga fler trånga och högljudda hundpensionat"
        checklist={checklist}
        isPhone={isPhone}
      />
      <FollowLocationLive isPhone={isPhone} is_grey={false} />
      <ReviewsSection isPhone={isPhone} />
      <WhatDoesItCost isPhone={isPhone} />
      <FaqQuestionSection
        isPhone={isPhone}
        questions={getRelevantFaqQuestionsForOwners()}
      />
    </div>
  );
}

const checklist = [
  {
    title: "För hundar i alla storlekar",
    subtitle:
      "Oavsett om din hund är stor eller liten, gammal eller ung, så försöker Woof hjälpa dig hitta en hundvakt som passar just din hund.",
  },
  {
    title: "Flexibla tider",
    subtitle:
      "Hitta en hundvakt som kan hjälpa dig med övernattningen när du behöver det. Till exempel när du ska på resa.",
  },
  {
    title: "Känn dig trygg att hunden har det bra när du reser",
    subtitle:
      "Med Woof kan du känna dig trygg att din hund har det bra när du reser bort. Få fotouppdateringar och följ hundens plats i realtid.",
  },
  {
    title: "BankID-verifierade hundvakter",
    subtitle:
      "Alla hundvakter som är anslutna till Woof är verifierade med BankID.",
  },
  {
    title: "Hemma hos hundvakten eller hos dig",
    subtitle:
      "Välj om övernattningen ska ske hemma hos hundvakten eller hos dig.",
  },
  {
    title: "Prisvärd övernattning",
    subtitle:
      "Olika hundvakter tar olika betalt för att hjälpa till med övernattning. Du och hundvakten kommer överens om priset tillsammans.",
  },

  {
    title: "Recensioner",
    subtitle:
      "Läs recensioner från andra hundägare innan du väljer en hundvakt.",
  },
];
