import { Helmet } from "react-helmet-async";
import BlueInfoSection from "../components/blue_info_section";
import HeroSection from "../components/hero_section";
import HowItWorksSection from "../components/how_it_works_section";
import OurServices from "../components/our_services";
import {
  APP_INSTALL_CLICK,
  copyParamsToNewLink,
  NAVIGATION_CLICK,
} from "../services/analytics";
import GreyTextSection from "../components/grey_text_section";
import GetTheAppSection from "../components/get_the_app_section";
import {
  FollowLocationLive,
  VerificationAndReview,
  YouChooseWho,
} from "./safety";

export default function PickAndDrop(props) {
  const { install_link, isPhone } = props;

  return (
    <div className="Body">
      <Helmet>
        <title>
          Få hjälp med hämtning & lämning av hunden | Hitta trygga hundrastare
          nära dig
        </title>
        <meta
          name="description"
          content="Woof gör det enkelt och tryggt att hitta hundrastare som kan hjälpa dig med hämtning & lämning. Få hjälp direkt eller förboka."
        />
      </Helmet>
      <HeroSection
        is_phone={isPhone}
        install_link={install_link}
        mobile_image={require("../assets/hamta_lamna_hero_mobile.webp")}
        mobile_title="Få trygg hjälp med hämtning & lämning"
        desktop_title="Få trygg hjälp med hämtning & lämning"
        desktop_subtitle="Woof hjälper dig hitta hundvakt på ett tryggt och smidigt sätt. Alla
        hundvakter är BankID-verifierade och du kan följa din hunds plats i
        realtid i appen."
        desktop_image={require("../assets/hamta_lamna_hero_desktop.webp")}
        button_text={"Ladda ner appen"}
        alt_text="En hund som är glad att se sin hundvakt"
      />
      {isPhone ? (
        <BlueInfoSection
          title={"Hitta en Woofer"}
          text={
            "På Woof kallar vi våra anslutna hundrastare för Woofers. Alla Woofers genomgår en 3-stegs verifieringsprocess med BankID, brottsregisterkontroll och manuell granskning."
          }
          link={copyParamsToNewLink("/sakerhet")}
          link_text={"Läs mer"}
          analytics={{
            event_name: NAVIGATION_CLICK,
            args: {
              where: `BLUE_HOME_INFO_SECTION_SAFETY`,
            },
          }}
        />
      ) : null}
      <OurServices
        is_phone={isPhone}
        install_link={install_link}
        list={pick_and_drop_reasons}
        title="Varför hitta hundrastare via Woof?"
        button_text={"Hitta hundrastare"}
        button_link={install_link}
        analytics={{
          event_name: APP_INSTALL_CLICK,
          args: {
            where: `WOOF_BENEFITS`,
          },
        }}
      />
      <GreyTextSection install_link={install_link} is_phone={isPhone} />
      <HowItWorksSection install_link={install_link} is_phone={isPhone} />
      <GetTheAppSection install_link={install_link} is_phone={isPhone} />
      <YouChooseWho install_link={install_link} isPhone={isPhone} />
      <VerificationAndReview isPhone={isPhone} />
      <FollowLocationLive isPhone={isPhone} />
      {/*   <InfoSection
        image={require("../assets/hamta_lamna_price.webp")}
        title={"Hur mycket kostar det att boka hämta & lämna via Woof?"}
        text={
          "När du bokar hämta & lämna via Woof så kommer du och din hundvakt överens om vilken ersättning du betalar. När du skapat en förfrågan i appen mottar du ansökningar med olika ersättningsförslag. Du kan sedan välja den hundvakt som passar dig bäst. Betala tryggt och säkert via appen när du fått tillbaka hunden efter uppdraget är slut."
        }
        buttonText={"Läs mer"}
        button_link={copyParamsToNewLink("/hundvakt-pris")}
        is_phone={isPhone}
        alt_text={"En glad hund ute med sin hundvakt i parken"}
        analytics={{
          event_name: NAVIGATION_CLICK,
          args: {
            where: `INFO_SECTION_HOW_HOW_MUCH_DOES_PICK_AND_DROP_COST`,
          },
        }}
      />
      <InfoSection
        image={require("../assets/hamta_lamna_security.webp")}
        title={"Hämtning & lämning med trygga & kärleksfulla hundvakter"}
        text={
          "Woof är skapat av hundägare, för hundägare. Vi förstår därför precis hur värdefull din fyrbenta vän är för dig. Därför har vi sett till att skapa en plattform med säkerhet i fokus. För att du alltid ska känna dig trygg att boka med Woof."
        }
        buttonText={"Kom igång"}
        button_link={install_link}
        is_phone={isPhone}
        is_grey={true}
        alt_text={"En glad hund som leker med sin hundvakt i trädgården"}
        analytics={{
          event_name: APP_INSTALL_CLICK,
          args: {
            where: `INFO_SECTION_PICK_AND_DROP_SAFETY`,
          },
        }}
      /> */}
    </div>
  );
}
const pick_and_drop_reasons = [
  {
    title: "Du väljer",
    text: "Välj bland 200+ hundrastare",
    large_screen_text: "Välj bland 200+ hundrastare",

    emoji: "💜",
  },
  {
    title: "Verifiering",
    text: "BankID-verifierade hundrastare",
    large_screen_text: "BankID-verifierade hundrastare",
    emoji: "✔️",
  },

  {
    title: "Livetracking",
    text: "Följ hundens plats i realtid",
    large_screen_text: "Följ hundens plats i realtid",

    emoji: "📍",
  },
  {
    title: "Prisvärt",
    text: "Förhandla pris med hundrastaren",
    large_screen_text: "Förhandla pris med hundrastaren",
    emoji: "💳",
  },
];
