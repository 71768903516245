import Header from "./components/header";
import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import Footer from "./components/footer";
import Home from "./pages/home";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";

import DogWalkingScreen from "./pages/dog_walking";
import DogSittingScreen from "./pages/dog_sitting";
import OvernightStay from "./pages/overnight_stay";
import ReactGA from "react-ga4";
import PaymentInfo from "./pages/payment_info";
import PickAndDrop from "./pages/pick_and_drop";
import GeneralTerms from "./pages/general_terms";
import PrivacyPolicy from "./pages/privacy_policy";
import SafetyPage from "./pages/safety";
import BecomeAWoofer from "./pages/become_a_woofer";
import CookieConsent, { Cookies } from "react-cookie-consent";
import GetTheAppDesktop from "./pages/get_the_app_desktop";
import PageNotFound from "./pages/404";
import CookiePreferences from "./components/cookie_preferences";
import {
  ACCEPT_COOKIES,
  LogEvent,
  copyParamsToNewLink,
  setConsent,
} from "./services/analytics";
import AboutPage from "./pages/about_page";
import ExploreWoofersScreen from "./pages/explore_woofers";
import WooferScreen from "./pages/wooferr_screen";
import { GlobalStateContext } from "./services/state_provider";

export const is_debug = !window.location.hostname.includes("getwoof");
console.log(`Debug: ${is_debug}`);
export const route_categories = ["Kom igång", "Fler sidor"];

export const routes = [
  {
    path: "/",
    name: "Hem",
  },
  {
    path: "/hitta-hundvakt",
    name: "Utforksa hundvakter",
    category: "Kom igång",
    excludeFromSubmenu: true,
  },
  {
    path: "/hundrastning",
    name: "Promenad",
    category: "Kom igång",
  },
  {
    path: "/hundpassning",
    name: "Hundpassning",
    category: "Kom igång",
  },
  {
    path: "/overnattning",
    name: "Övernattning",
    category: "Kom igång",
  },
  {
    path: "/hamta-lamna",
    name: "Hämta & lämna",
    category: "Kom igång",
  },

  {
    path: "/sakerhet",
    name: "Säkerhet",
    category: "Fler sidor",
  },
  {
    path: "/bli-en-hundvakt",
    name: "Bli en hundvakt",
    category: "Fler sidor",
  },
  {
    path: "/hundvakt-pris",
    name: "Vad kostar det?",
    category: "Fler sidor",
  },
  {
    path: "/om-oss",
    name: "Om oss",
    category: "Fler sidor",
  },
  {
    path: "https://intercom.help/woof-3b60c1cf521b/sv",
    name: "Hjälp",
    category: "Fler sidor",
  },
];

export const why_woof_bullets = [
  {
    text: "Du väljer vem som ska hjälpa",
  },

  {
    text: "Följ din hunds plats i realtid",
  },
  {
    text: "Hundvakter verifierade med BankID",
  },
];
/*  

  
 */
export default function App() {
  const {
    install_link,
    isPhone,
    gclid,
    woofers,
    address,
    setAddress,
    woofersState,
  } = useContext(GlobalStateContext);
  const [isCookiePrefsPopupOpen, setIsCookiePrefsPopupOpen] = useState(false);

  const handleClosePopup = (acceptedValues) => {
    setIsCookiePrefsPopupOpen(false);
    console.log(`handleClosePopup: ${acceptedValues}`);

    if (acceptedValues === undefined) return;

    if (acceptedValues != null && acceptedValues.length > 1) {
      Cookies.set("CookieConsent", "true");
      const stringAccepted = JSON.stringify(acceptedValues);
      console.log(`Accepted cookies: ${stringAccepted}`);
      Cookies.set("AllowedCookies", stringAccepted);
      const hasAnalytics = acceptedValues.includes("Analys");
      const hasStatistics = acceptedValues.includes("Statistik");
      console.log(`ON ACCEPTED COOKIES CLOSE PUPUL ACCEPTEDVALUES`);
      onCookiesAccepted(hasAnalytics, hasStatistics);
    } else {
      Cookies.set("CookieConsent", "false");

      removeCookies();
    }
  };

  return (
    <div>
      <Header
        install_link={install_link}
        isPhone={isPhone}
        gclid={gclid}
        woofers={woofers}
        address={address}
        setAddress={setAddress}
        woofersState={woofersState}
      />
      <BrowserRouter>
        <PageViewLogger />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="/hundrastning"
            element={
              <DogWalkingScreen
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="/hundpassning"
            element={
              <DogSittingScreen
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="/overnattning"
            element={
              <OvernightStay
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="/hundvakt-pris"
            element={
              <PaymentInfo
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="/hamta-lamna"
            element={
              <PickAndDrop
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="/anvandarvillkor"
            element={
              <GeneralTerms
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />{" "}
          <Route
            path="/integritetspolicy"
            element={
              <PrivacyPolicy
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="/sekretesspolicy"
            element={
              <Navigate to={copyParamsToNewLink("/integritetspolicy")} />
            }
          />
          <Route
            path="/sakerhet"
            element={
              <SafetyPage
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />{" "}
          <Route
            path="/bli-en-hundvakt"
            element={
              <BecomeAWoofer
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="/hitta-hundvakt"
            element={
              <ExploreWoofersScreen
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="/om-oss"
            element={
              <AboutPage
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="/woofer"
            element={
              <WooferScreen
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          <Route
            path="*"
            element={
              <PageNotFound
                install_link={install_link}
                isPhone={isPhone}
                gclid={gclid}
                woofers={woofers}
                address={address}
                setAddress={setAddress}
                woofersState={woofersState}
              />
            }
          />
          {!isMobile && !isTablet && (
            <Route
              path="/hamta-appen"
              element={
                <GetTheAppDesktop
                  install_link={install_link}
                  isPhone={isPhone}
                  gclid={gclid}
                  woofers={woofers}
                  address={address}
                  setAddress={setAddress}
                  woofersState={woofersState}
                />
              }
            />
          )}
        </Routes>
      </BrowserRouter>
      {window.location.href.includes("woofer/?woofer_id") ? (
        <div></div>
      ) : (
        <CookieConsent
          debug={window.location.hostname.includes("localhost")}
          flipButtons
          style={{
            background: "#ffd76a",
            color: "#ECEEF1",
          }}
          enableDeclineButton
          declineButtonText="Hantera inställningar"
          buttonText="Godkänn alla"
          onAccept={() => {
            console.log(`ON ACCEPTED COOKIES TAP ACCEPT`);

            onCookiesAccepted(true, true);
            LogEvent(ACCEPT_COOKIES);
          }}
          onDecline={() => {
            Cookies.set("CookieConsent", "false");

            setIsCookiePrefsPopupOpen(true);
          }}
          declineButtonStyle={{
            fontSize: "15px",
            backgroundColor: "#494452",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "25px",
            paddingRight: "25px",

            color: "#ffd76a",
            borderRadius: "5px",
          }}
          buttonStyle={{
            fontSize: "15px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "25px",
            paddingRight: "25px",
            backgroundColor: "#494452",
            color: "#ffd76a",
            borderRadius: "5px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                color: "black",
                fontWeight: "bold",
                marginBottom: "15px",
                fontSize: "16px",
              }}
            >
              Cookies 🍪
            </span>
            <span style={{ color: "black" }}>
              Vi använder cookies för att förbättra din upplevelse på vår
              hemsida
            </span>
          </div>
        </CookieConsent>
      )}
      <CookiePreferences
        isVisible={isCookiePrefsPopupOpen}
        onClose={handleClosePopup}
      />
      <Footer />
    </div>
  );
}

export function removeCookies() {
  console.log("NEKA COOKIES");
  /*  const gaScript = document.getElementById("ga");
  const tagScript = document.getElementById("gtag"); */
  const hotjarScript = document.getElementById("hotjar");

  setConsent("denied", false);

  /*  if (gaScript) {
    gaScript.parentNode.removeChild(gaScript);
  }

  if (tagScript) {
    tagScript.parentNode.removeChild(tagScript);
  } */

  if (hotjarScript) {
    hotjarScript.parentNode.removeChild(hotjarScript);
  }

  const allCookies2 = document.cookie; //STRING
  //use allCookies2 to make an object

  let cookieObject = {};

  const splitted = allCookies2.split(";");

  for (let i = 0; i < splitted.length; i++) {
    const key = splitted[i].split("=")[0];
    const value = splitted[i].split("=")[1];
    cookieObject[key] = value;
  }

  console.log(`All cookies2: ${allCookies2}, }`);

  console.log(`CookieObject: ${JSON.stringify(cookieObject)}`);

  Object.entries(cookieObject).forEach(([key, value]) => {
    if (is_debug) {
      console.log(`Key: ${key}, value: ${value}`);
    }
    if (key !== "CookieConsent") {
      if (is_debug) {
        console.log(`Removing cookie ${key}`);
      }
      Cookies.remove(key);
    } else console.log("NOT REMOVING COOKIECONSENT");
  });
}

export function onCookiesAccepted(include_hotjar, include_ga) {
  console.log("SOME OR ALL COOKIES ARE ACCEPTED");
  if (include_ga) {
    setConsent("granted", false);
    console.log("SETTING CONSENT TO GRANTED");
  }
  if (include_hotjar) insertHotjarCode();
}

export function insertGaCode() {
  console.log(`HOSTNAME: ${window.location.hostname}`);

  ReactGA.initialize(is_debug ? "G-8WEE4CBBMX" : "G-QZMZRG139C");

  // Check for cookie consent and set consent accordingly
  const cookieConsent = Cookies.get("CookieConsent");

  // Create and append the gtag script
  const tagScript = document.createElement("script");
  tagScript.async = true;
  tagScript.src = is_debug
    ? "https://www.googletagmanager.com/gtag/js?id=G-8WEE4CBBMX"
    : "https://www.googletagmanager.com/gtag/js?id=G-QZMZRG139C";
  tagScript.id = "gtag";

  // Create the inline script for initializing gtag and setting consent
  const initScript = document.createElement("script");
  initScript.id = "ga-init";
  initScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { 
      dataLayer.push(arguments);
    }
    window.gtag = gtag;

    gtag("js", new Date());
    
    if ('${cookieConsent}' !== "true") {
      gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
      });
    } else {
      gtag("consent", "default", {
        ad_storage: "granted",
        ad_user_data: "granted",
        ad_personalization: "granted",
        analytics_storage: "granted",
      });
    }

    gtag("config", "${is_debug ? "G-8WEE4CBBMX" : "G-QZMZRG139C"}");
    gtag("config", "AW-10891815645");
  `;

  // Append both scripts to the document
  document.head.appendChild(tagScript);
  document.head.appendChild(initScript);
}

export function insertHotjarCode() {
  console.log(`HOSTNAME :${window.location.hostname}`);
  if (is_debug) {
    console.log("NOT INSERTING HOTJAR BECAUSE NOT ON LIVEG");
    return;
  }

  console.log("INSERTING HOTJAR CODE");

  const hotjarScript = document.createElement("script");
  hotjarScript.innerHTML = `
  (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:5027012,hjsv:6,hjdebug:${is_debug}};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`;
  hotjarScript.id = "hotjar";

  console.log("APPENDING HOTJAR SCRIPT");

  document.head.appendChild(hotjarScript);
}

function PageViewLogger() {
  console.log(`Pageviewlogger running`);

  const location = useLocation();
  useEffect(() => {
    const consent = Cookies.get("CookieConsent");

    if (consent === "true") {
      if (window.location.hostname.includes("getwoof")) {
        console.log(`Pageview: ${location.pathname}`);
        ReactGA.send({
          hitType: "pageview",
          page: location.pathname,
        });
      }
    }
  }, [location]);

  return null;
}

export const FAQ_QUESTIONS = [
  {
    q: "Vad är en Woofer?",
    category: "general",
    a: "På Woof kallar vi alla hundvakter och hundrastare för Woofers. Alla Woofers är enskilda näringsidkare som registrerat sig på Woof för att erbjuda hundpassning- och rastningstjänster.",
  },
  {
    q: "Vilka tjänster erbjuder hundvakter på Woof?",
    category: "general",
    a: "I Woof-appen kan du välja mellan fyra tjänster. Vilken tjänst du ska välja beror på dina behov (se exempel nedan):\n- Promenad:\nNär du inte har tid eller lust att ta ut hunden. Välj mellan 30, 60 och 90 minuter.\n- Hundpassning:\nNär du behöver hjälp att hitta en hundvakt som hjälper dig att passa hunden i upp till 12 timmar. Hemma hos dig eller din hundvakt.\n- Övernattning:\nHitta en kärleksfull hundvakt som övernattar med din hund, hemma hos dig eller hundvakten. Perfekt om du ska resa iväg i några dagar!\n- Hämta & lämna:\n När du behöver hjälp att ta hunden från en plats till en annan. Till exempel om du inte hinner hämta hunden på dagis efter jobbet.",
  },
  {
    q: "Är det gratis att ladda ner Woof?",
    category: "general",
    a: "Woof är helt gratis att ladda ner från App Store eller Google Play.",
  },
  {
    q: "Hur registrerar jag mig?",
    category: "general",
    a: "När du har laddat ner appen och öppnat den för första gången får du två val, att registrera dig som hundvakt eller hundägare. Välj den kontotyp du vill registrera och kom igång direkt. Ange sedan ditt telefonnummer eller din e-postadress och verifiera den via sms kod eller att skapa ett lösenord. ",
  },
  {
    q: "Hur kontaktar jag Woofs kundsupport?",
    category: "general",
    a: 'Har du frågor eller funderingar? Då kan du alltid kontakta Woof-supporten direkt i appen. Navigera bara till profil skärmen och skrolla längst ner. Där hittar du knappen "vanliga frågor och support". Du kan även kontakta oss via denna länk:',
    link: "https://intercom.help/woof-3b60c1cf521b/sv",
    link_text: "Kontakta oss",
    new_tab: true,
  },
  {
    q: "Hur många hundar passas / rastas samtidigt",
    category: "dog_owner",
    a: "På Woof kan hundvakter bara ta ett uppdrag i taget, vilket innebär att din hund inte kommer att passas med någon annan hund. Detta gäller så länge hundvakten inte har en egen hund, då är det upp till dig och hundvakten att tillsammans avgöra om båda hundarna kan passas tillsammans.",
  },
  {
    q: "Vad kostar det att boka en hundvakt?",
    category: "dog_owner",
    a: "Vad det kostar att boka en hundvakt beror helt på vad du själv är villig att betala. När du skapar en förfrågan har du som hundägare möjlighet att själv välja hur mycket du vill betala. Om du har svårt att bestämma dig för vad du vill erbjuda för ersättning, kan du välja att låta ansökande hundvakter föreslå en ersättning de känner sig bekväma med.",
    link: copyParamsToNewLink("/hundvakt-pris"),
    link_text: "Läs mer",
  },
  {
    q: "Hur hittar jag en hundvakt eller hundrastare",
    category: "dog_owner",
    a: "På Woof finns det två sätt att hitta hundvakter och hundrastare. Antingen genom att skapa en öppen förfrågan eller genom att skicka en direktförfrågan. När du skapar en öppen förfrågan gör du den synlig för alla hundvakter i appen, som sedan kan skicka ansökningar till dig. Skickar du en direktförfrågan kommer den bara att synas för hundvakten du skickar den till.",
  },
  {
    q: "Vad gör Woof för att öka säkerheten?",
    category: "dog_owner",
    a: "Woof grundades med målet att göra livet för alla hundägare enklare genom att erbjuda en plattform som hjälper en hitta trygga hundvakter. Därför arbetar vi outtröttligt med att erbjuda tjänster med säkerheten som högsta prioritet. Här är några \n-Alla hundvakter är BankID-verifierade.\n-Detaljerade hundvaktsprofiler med verifierade tvåvägsomdömen från hundägare som du.\n-Alla hundvaktsprofiler är manuellt granskade av Woof-teamet.\n-Woof antar endast personer som vill bli hundvakter om de har ett tomt belastningsregister.\n-Snabb och personlig support.\n-Meddelanden, samtal och fotodelning direkt i appen.\n-Säkra och smidiga betalningar med vår betalpartner Stripe eller Swish.",
  },

  {
    q: "Hur betalar jag min hundvakt?",
    category: "dog_owner",
    a: 'Du betalar ingenting förrän bokningen är slutförd och du fått tillbaka din hund enligt dina önskemål. När du fått tillbaka hunden behöver du bekräfta att den blivit avlämnad av hundvakten. Sedan betalar du snabbt och smidigt direkt i appen från bokningsskärmen, genom att klicka på "betala min Woofer".',
  },
  {
    q: "Hur många hundar passas / rastas samtidigt",
    category: "woofer",
    a: "På Woof kan du som hundvakt bara ta ett uppdrag i taget. Om du har en egen hund, är det upp till dig och hundägaren att bedöma om de kan passas eller rastas tillsammans.",
  },
  {
    q: "Hur får jag betalt?",
    category: "woofer",
    a: "Du väljer om du vill få betalt via Swish eller banköverföring. Om du väljer Swish så tar Woof en avgift på 7.5% av utbetalningsbeloppet.",
  },
  {
    q: "Hur deklararas mina intäkter?",
    category: "woofer",
    a: "I slutet av året lämnar Woof in kontrolluppgifter till Skatteverket, som redovisar hur mycket du tjänat under året.",
  },

  {
    q: "Vart kan jag utföra uppdragen?",
    category: "woofer",
    a: "Hundägaren väljer vilken plats de vill att uppdraget skall ske när de lägger ut ett uppdrag. Om uppdraget gäller Promenad eller Hämtning & lämnning är det utomhus, oftast i en park. Om uppdraget gäller Hundpassning eller Övernattning väljer hundägaren om uppdraget skall genomföras hemma hos dem eller hemma hos dig. Du ser platsen innan du ansöker till uppdraget.",
  },
];
