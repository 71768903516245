import "./checklist_section.css";
import WoofRoundedButton from "./rounded_woof_button";
import WoofBullet from "./woof_bullet";

export default function ChecklistSection(props) {
  const { title, checklist, isPhone, install_link } = props; 
  if (!isPhone) return <div></div>;
  return (
    <div className="Checklist-section">
      <h4 className="Checklist-section-title">{title}</h4>
      <div style={{ paddingBottom: "10px" }}>
        {checklist.map((item, index) => {
          return (
            <div className="Checklist-section-item" key={index}>
              <WoofBullet no_padding={true} size={30} color={"#876ab4"} />
              <div className="Checklist-section-item-content">
                <p className="Checklist-section-item-title">{item.title}</p>
                <p className="Checklist-section-item-subtitle">
                  {item.subtitle}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <WoofRoundedButton text={"Kom igång"} link={install_link} secondary={true} />
    </div>
  );
}
