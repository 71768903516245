import { LogEvent } from "../services/analytics";
import "./blue_info_section.css";

function LogInstallClick(analytics) {
  console.log(`Logging ${analytics.event_name}`);
  /* "NAVIGATION_CLICK", "CLICKED_SERVICE_BOX", null, `SERVICES_SECTION` */

  LogEvent(analytics.event_name, analytics.args ?? {});
}

export default function BlueInfoSection(props) {
  const analytics = props.analytics;
  return (
    <div className="Blue-safety-reminder">
      <h2 className="Blue-safety-section-title">
        {props.title ?? "Säkerhet är vår prioritet"}
      </h2>
      <p className="Blue-safety-section-subtitle">
        {props.text ??
          "Alla hundvakter på Woof genomgår en extensiv bakgrundskontroll som innehåller BankID verifiering, kontroll av belastningsregister och manuell granskning. Dessutom kan du följa din hunds plats live i appen när du bokar via Woof."}
      </p>

      {props.link != null ? (
        <div className="Blue-safety-section-link">
          {" "}
          <a
            href={props.link}
            onClick={() => {
              LogInstallClick(analytics);
            }}
          >
            {props.link_text ?? "Läs mer om säkerhet"}
          </a>
        </div>
      ) : null}
    </div>
  );
}

/* hamta_lamna_hero_desktop */
