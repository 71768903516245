import { copyParamsToNewLink, LogEvent, SERVICE_CARD_NAVIGATION_CLICK } from "../services/analytics";
import "./our_services.css";
import WoofRoundedButton from "./rounded_woof_button";

function LogInstallClick() {
  console.log("Logging SERVICE CARD click");

  LogEvent(SERVICE_CARD_NAVIGATION_CLICK, {
    where: "OUR_SERVICES",
  });
}

export default function OurServices(props) {
  const is_phone = props.is_phone;
  const analytics = props.analytics;

  return (
    <div className="Our-services">
      <h2 className="Our-services-title">{props.title}</h2>
      <div className="Our-services-container">
        {props.list.map((service, index) => {
          if (service.link == null) {
            return (
              <ServiceCard service={service} key={index} is_phone={is_phone} />
            );
          }

          return (
            <a
              href={copyParamsToNewLink(service.link)}
              className="Service-card-link"
              key={index}
              onClick={() => {
                LogInstallClick();
              }}
            >
              <ServiceCard service={service} is_phone={is_phone} />
            </a>
          );
        })}
      </div>
      {props.button_text && props.button_link && is_phone ? (
        <div style={{ paddingTop: "20px" }}>
          <WoofRoundedButton
            onClick={() => {
              if (analytics) {
                LogEvent(analytics.event_name, analytics.args);
              }
            }}
            link={props.button_link}
            text={props.button_text}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

function ServiceCard(props) {
  const service = props.service;

  return (
    <div className="Service-card">
      <div className="Service-emoji-circle">{service.emoji}</div>
      <h3 className="Service-card-title">{service.title}</h3>
      <p className="Service-card-description">
        {props.is_phone ? service.text : service.large_screen_text}
      </p>
    </div>
  );
}
/* const dog_walking_reasons = [
  {
    title: "Du väljer",
    text: "Välj bland flera hundrastare i ditt område",
    large_screen_text: "Välj bland flera hundrastare i ditt område",

    emoji: "💜",
  },
  {
    title: "BankID-verifiering",
    text: "Alla hundrastare verifieras och granskas manuellt",
    large_screen_text: "Alla hundrastare verifieras och granskas manuellt",
    emoji: "✔️",
  },

  {
    title: "Livetracking",
    text: "Följ hundens plats realtid under promenaden",
    large_screen_text: "Följ hundens plats realtid under promenaden",

    emoji: "📍",
  },
  {
    title: "Prisvärt",
    text: "Du och hundrastraren kommer överens om ersättningen",
    large_screen_text: "Du och hundrastraren kommer överens om ersättningen",
    emoji: "💳",
  },
];
 */
