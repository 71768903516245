export default function ReviewsSection(props) {
  return (
    <div
      className="Reviews-section"
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: "#f5f6f8",
        paddingTop: "50px",
        paddingBottom: "50px",
        marginTop: "30px",
        marginBottom: "30px",
      }}
    >
      <h4
        style={{
          textAlign: "center",
          marginBottom: "30px",
        }}
      >
        Vad säger hundägare om Woof?
      </h4>
      <ReviewCard
        title={"Antonio och Dino"}
        text={
          "Jag och Dino har använt Woof flera gånger och är supernöjda. Det är enkelt och tryggt. Vi har fått hjälp av flera hundvakter och alla har varit jättebra. Jag var lite nervös innnan första gången, men jag och hundvakten Maja träffades innan så Dino fick bekanta sig."
        }
        image={require("../assets/antonio_review.webp")}
      />
      <div
        style={{
          height: "30px",
        }}
      ></div>
      <ReviewCard
        title={"Lia och Bella"}
        text={
          "Jag skulle resa och visste inte vad jag skulle göra med Bella. Woof hjälpte oss hitta hundvaken Johanna. Bella trivdes jättebra och jag kunde följa med i appen. Jag är supernöjd och kommer använda Woof igen."
        }
        image={require("../assets/lia_review.webp")}
      />
    </div>
  );
}

export function ReviewCard(props) {
  const { title, text, image } = props;
  return (
    <div
      className="Review-card"
      style={{
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "5px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
        display: "flex",
      }}
    >
      <img
        src={image}
        alt={title}
        style={{
          width: "60px",
          height: "60px",
          borderRadius: "100px",
          marginRight: "15px",
        }}
      ></img>
      <div className="Review-text">
        <p
          style={{
            fontFamily: "Montserrat",

            fontWeight: "600",
            fontSize: "16px",
            paddingBottom: "10px",
            color: "#2c2044",
          }}
        >
          {title}
        </p>
        <p>{text}</p>
      </div>
    </div>
  );
}
