import { Helmet } from "react-helmet-async";
import HeroSection from "../components/hero_section";

import InfoSection from "../components/info_section";
import FaqQuestionSection from "../components/faq_question_section";
import { getRelevantFaqQuestionsForOwners } from "./home";

export default function AboutPage(props) {
  const { install_link, isPhone } = props;
  const subtitle =
    "Välkommen till Woof, den trygga och smidiga lösningen för hundägare, som behöver hjälp att hitta pålitliga och proffsiga hundvakter. På denna sida kan du lära dig mer om Woof och teamet bakom appen.";
  return (
    <div>
      <Helmet>
        <title>Woof | Om oss</title>
        <meta
          name="description"
          content="Läs mer om Woof och hur vi hjälper hundägare och hundvakter att hitta varandra på ett tryggt och smidigt sätt."
        />
      </Helmet>
      <HeroSection
        is_phone={isPhone}
        install_link={install_link}
        mobile_image={require("../assets/sebbe_virre.webp")}
        mobile_title="Om Woof"
        desktop_title="Om Woof"
        desktop_subtitle={subtitle}
        mobile_subtitle={subtitle}
        desktop_image={require("../assets/laptop_version_hero_image.webp")}
        alt_text="Victor och Sebastian, grundare av Woof på promenad"
        skip_button={true}
      />
      <NumbersBoxSection />
      {/*  <IconTextSection /> */}
      <InfoSection
        isPhone={isPhone}
        text={
          "En dag 2021 hade Victor, en av våra grundare, inte tid att hämta hunden Zoe från hunddagis. Det var då idén om en app som hjälper hundägare att hitta pålitlig hjälp med hundpassning och hundrastning föddes. Sedan vår lansering i september 2022 har vårt mål varit att förenkla vardagen för hundägare genom att erbjuda en säker och smidig plattform för att hitta betrodda hundvakter och rastare. Oavsett om du behöver hjälp med kort varsel eller lång planering, så finns Woof här för att hjälpa dig."
        }
        buttonText={"Ladda ner appen"}
        button_link={install_link}
        install_link={install_link}
        image={require("../assets/av_hundagare_for_hundagare.webp")}
        title={"Av hundägare, för hundägare"}
        alt_text={"Victor och Sebastian, grundare av Woof"}
      />

      <div
        className="Meet-The-Team"
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "30px",
          paddingBottom: "30px",
          marginBottom: "30px",
          backgroundColor: "#f5f7f8",
        }}
      >
        <h4 style={{ paddingBottom: "20px" }}>Möt Woof-teamet</h4>
        <div>
          {members.map((member) => {
            const isLast = members.indexOf(member) === members.length - 1;
            return (
              <div
                className="Team-member"
                style={{
                  display: "flex",
                  paddingBottom: isLast ? "0px" : "20px",
                }}
              >
                <img
                  alt={member.name}
                  src={member.image}
                  style={{
                    height: "125px",
                    width: "125px",
                    borderRadius: "100%",
                  }}
                ></img>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingLeft: "20px",
                  }}
                >
                  <p
                    style={{
                      paddingBottom: "5px",
                      fontWeight: "600",
                    }}
                  >
                    {member.name}
                  </p>
                  <p>{member.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <FaqQuestionSection
        isPhone={isPhone}
        questions={getRelevantFaqQuestionsForOwners()}
      />
    </div>
  );
}

const members = [
  {
    name: "Victor Kennedy",
    image: require("../assets/victor_webapp.webp"),
    description: "VD, medgrundare och programmerare",
  },
  {
    name: "Sebastian Olsson",
    image: require("../assets/sebbe_webapp.webp"),
    description: "Vice VD, medgrundare och designer",
  },
  {
    name: "Zoe Cockapoo",
    image: require("../assets/zoe_webapp.webp"),
    description: "Chief Happiness Officer",
  },
];

function NumbersBoxSection(props) {
  return (
    <div
      className="Numbers-Section"
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#f5f7f8",
        padding: "25px",
        marginBottom: "30px",
      }}
    >
      <NumberBox number={"200+"} text={"Aktiva hundvakter"} />
      <NumberBox number={"1000+"} text={"Bokningar"} />
      <NumberBox number={"4.3"} text={"App-Store rating"} />
    </div>
  );
}

function NumberBox(props) {
  const { number, text } = props;
  return (
    <div
      className="Number-Box"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h4
        style={{
          color: "#876ab4",
        }}
      >
        {number}
      </h4>
      <p>{text}</p>
    </div>
  );
}
/* function IconTextSection(props) {
  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "30px",
      }}
    >
      <img
        src={require("../assets/our_vision.png")}
        style={{ maxWidth: "112px", paddingBottom: "15px" }}
      ></img>
      <h2 style={{ paddingBottom: "15px" }}>Av hundägare, för hundägare</h2>
      <p></p>
    </div>
  );
}
 */
