import { Helmet } from "react-helmet-async";
import { FAQ_QUESTIONS } from "../App";
import BlueInfoSection from "../components/blue_info_section";
import FaqQuestionSection from "../components/faq_question_section";
import HeroSection from "../components/hero_section";
import OurServices from "../components/our_services";
import {
  APP_INSTALL_CLICK,
  copyParamsToNewLink,
  NAVIGATION_CLICK,
} from "../services/analytics";
import InfoSection from "../components/info_section";
import ReviewsSection from "../components/reviews_section";

export default function SafetyPage(props) {
  const { install_link, isPhone } = props;

  const faq_questions = getRelevantFaqQuestions();
  return (
    <div className="Body">
      <Helmet>
        <title>Woof hundvakt | Din hund är i trygga händer</title>
        <meta
          name="description"
          content="Woof är appen där du och din pälskling hittar trygga och pålitliga hundvakter. Läs mer om våra säkerhetsåtgärder."
        />
      </Helmet>
      <HeroSection
        is_phone={isPhone}
        install_link={install_link}
        button_text="Hitta hundvakt"
        mobile_image={require("../assets/safety_hero.webp")}
        mobile_title="Hur vi stödjer säkrare djurvårdsupplevelser"
        desktop_title="Hur vi stödjer säkrare djurvårdsupplevelser"
        desktop_image={require("../assets/safety_hero_desktop.webp")}
        desktop_subtitle="När du bokar djurvård via Woof kan du känna dig trygg. Vi har skapat en plattform med säkerhet i fokus."
        bullet_list={[
          {
            text: "BankID-verifierade hundvakter",
          },
          {
            text: "Följ hundens plats i realtid",
          },
          {
            text: "Manuell granskning av alla hundvakter",
          },
        ]}
        alt_text="Hundrastare i parken med hund"
      />
      {isPhone ? (
        <BlueInfoSection
          title="Några frågor eller funderingar?"
          text="Vår kundsupport finns tillgänglig 09-17 på vardagar. Tveka inte att höra av dig till oss om du har några frågor eller funderingar."
          link="https://intercom.help/woof-3b60c1cf521b/sv"
          link_text="Kontakta oss"
        />
      ) : null}{" "}
      <OurServices
        is_phone={isPhone}
        install_link={install_link}
        list={safety_bullets}
        title="Trygghet är prioritet #1"
        button_text={"Kom igång"}
        button_link={install_link}
        analytics={{
          event_name: APP_INSTALL_CLICK,
          args: {
            where: `WOOF_BENEFITS`,
          },
        }}
      />
      <YouChooseWho install_link={install_link} isPhone={isPhone} />
      <VerificationAndReview
        install_link={install_link}
        isPhone={isPhone}
        button_text="Ladda ner appen"
      />
      <ReviewsSection isPhone={isPhone} />
      <FollowLocationLive
        button_link={install_link}
        isPhone={isPhone}
        button_text={"Ladda ner Woof-appen"}
        is_grey={false}
      />
      <FaqQuestionSection
        install_link={install_link}
        is_phone={isPhone}
        questions={faq_questions}
      />
    </div>
  );
}
function getRelevantFaqQuestions() {
  return FAQ_QUESTIONS.filter((question) =>
    ["dog_owner", "general"].includes(question.category)
  );
}

/* const bullets = [
  {
    title: "Verifierade hundvakter",
    subtitle:
      "Alla hundvakter och hundrastare registrerade hos Woof har genomgått en BankID verifiering. Woof tillåter dessutom endast personer som vill bli hundvakter om de har ett tomt belastningsregister. \n Läs verifierade omdömen som hundvakter fått från hundägare som du.",
    image: require("../assets/verified_woofers.webp"),
    alt_text: "Säkra hundpassningstjänster- alla hundvakter är verifierade",
  },

  {
    title: "Manuellt granskade hundvakter",
    subtitle:
      "Woof-teamet granskar alla hundvakter manuellt innan de får börja ta emot uppdrag. Vi ser till att alla hundvakter är seriösa och har en genuin kärlek till hundar.",
  },
  {
    title: "Håll dig uppdaterad om hur hunden har det",
    subtitle:
      "Slipp undra hur det går! Följ hundens plats i realtid under hela uppdraget. Motta fotouppdateringar och meddelanden från din hundvakt och se hur din hund har det.",
    image: require("../assets/livetracking.webp"),
    alt_text: "Följ hundens plats i realtid i appen",
  },
  {
    title: "Dedikerad kundsupport",
    subtitle:
      "Våra kundtjänstmedarbetare finns tillgängliga för att hjälpa dig med allt du behöver. Vi finns här för att hjälpa dig med allt från att hitta rätt hundvakt till att lösa eventuella problem som kan uppstå under uppdraget. Öppet 09-17 på vardagar.",
  },
]; */
const safety_bullets = [
  {
    title: "Du väljer",
    text: "Välj bland 200+ hundvakter",
    large_screen_text: "Välj bland 200+ hundvakter",

    emoji: "💜",
  },
  {
    title: "Verifiering",
    text: "BankID-verifierade hundvakter",
    large_screen_text: "BankID-verifierade hundvakter",
    emoji: "✔️",
  },
  {
    title: "Livetracking",
    text: "Följ hundens plats i realtid",
    large_screen_text: "Följ hundens plats i realtid",

    emoji: "📍",
  },
  {
    title: "Granskning",
    text: "Hundvakter granskas manuellt av Woof-teamet",
    large_screen_text: "Hundvakter granskas manuellt av Woof-teamet",
    emoji: "📋",
  },
];

export function YouChooseWho(props) {
  const is_grey = props.is_grey ?? true;
  const currentLink = window.location.href;
  const use_hundrastare =
    currentLink.includes("hundrastning") || currentLink.includes("hamta-lamna");

  const text =
    "Alla hundar och människor är olika. Därför är det upp till dig och din hund att välja den hundvakt som passar er bäst. På Woof kan du välja bland 200+ BankID-verifierade hundvakter.";
  return (
    <InfoSection
      image={require("../assets/happy_dog_sitter.webp")}
      title={"Du väljer vem som ska hjälpa"}
      text={
        use_hundrastare
          ? text
              .replaceAll("hundvakter", "hundrastare")
              .replaceAll("hundvakt", "hundrastare")
          : text
      }
      buttonText={`Utforksa ${
        use_hundrastare ? "hundrastare" : "hundvakter"
      } i närområdet`}
      button_link={copyParamsToNewLink("/hitta-hundvakt")}
      is_phone={props.isPhone}
      is_grey={is_grey}
      alt_text={"Hundvakt som leker med hund"}
      analytics={{
        event_name: NAVIGATION_CLICK,
        args: {
          where: `INFO_SECTION_SAFETY_YOU_CHOOSE`,
        },
      }}
    />
  );
}
export function VerificationAndReview(props) {
  const is_grey = props.is_grey ?? false;
  const currentLink = window.location.href;
  const use_hundrastare =
    currentLink.includes("hundrastning") || currentLink.includes("hamta-lamna");

  const text =
    "Alla hundvakter som är anslutna till Woof har genomgått en 3-stegs verifieringsprocess. Detta inkluderar BankID-verifiering, brottsregisterkontroll och manuell granskning av Woof-teamet.";
  return (
    <InfoSection
      is_grey={is_grey}
      image={require("../assets/playing_with_ball.webp")}
      title={"Verifiering och granskning"}
      text={
        use_hundrastare
          ? text
              .replaceAll("hundvakter", "hundrastare")
              .replaceAll("hundvakt", "hundrastare")
          : text
      }
      buttonText={props.button_text ?? "Läs mer"}
      button_link={props.install_link ?? copyParamsToNewLink("/sakerhet")}
      is_phone={props.isPhone}
      alt_text={"En hundvakt och hund som leker med en boll"}
      analytics={{
        event_name: APP_INSTALL_CLICK,
        args: {
          where: `INFO_SECTION_SAFETY_VERIFICATION`,
        },
      }}
    />
  );
}

export function FollowLocationLive(props) {
  const is_grey = props.is_grey ?? true;
  const currentLink = window.location.href;
  const use_hundrastare =
    currentLink.includes("hundrastning") || currentLink.includes("hamta-lamna");

  const text =
    "När du bokar en hundvakt via Woof kan du följa hundens plats i realtid under hela uppdraget via vår app. Håll dig uppdaterad genom att chatta eller ringa din hundvakt och ta emot fotouppdateringar.";
  return (
    <InfoSection
      image={require("../assets/dog_on_walk_hugging.webp")}
      title={"Följ hundens plats i realtid"}
      text={
        use_hundrastare
          ? text
              .replaceAll("hundvakt", "hundrastare")
              .replaceAll("hundvakter", "hundrastare")
          : text
      }
      buttonText={props.button_text ?? "Läs mer"}
      button_link={props.button_link ?? copyParamsToNewLink("/sakerhet")}
      is_phone={props.isPhone}
      is_grey={is_grey}
      alt_text={"Hund glad hund på promenad med sin hundrastare"}
      analytics={{
        event_name: APP_INSTALL_CLICK,
        args: {
          where: `INFO_SECTION_SAFETY_LIVETRACKING`,
        },
      }}
    />
  );
}
