import { Helmet } from "react-helmet-async";
import { FAQ_QUESTIONS } from "../App";
import FaqQuestionSection from "../components/faq_question_section";
import HeroSection from "../components/hero_section";
import HowItWorksSection from "../components/how_it_works_section";
import InfoSection from "../components/info_section";
import OurServices from "../components/our_services";
import { APP_INSTALL_CLICK } from "../services/analytics";

export default function BecomeAWoofer(props) {
  const { install_link, isPhone } = props;
  const faq_questions = getRelevantFaqQuestions();
  return (
    <div className="Body">
      <Helmet>
        <title>Bli en hundvakt | Få betalt för att umgås med hundar</title>
        <meta
          name="description"
          content="Kom igång som hundvakt snabbt och smidigt med Woof. Hitta hundägare i ditt närområde - börja passa och rasta hundar."
        />
      </Helmet>
      <HeroSection
        is_phone={isPhone}
        install_link={install_link}
        desktop_image={require("../assets/become_woofer_hero_desktop.webp")}
        desktop_title="Bli en hundvakt"
        mobile_title="Bli en hundvakt"
        mobile_image={require("../assets/become_woofer_hero_mobile.webp")}
        desktop_subtitle="Ta ut och passa hundar, tjäna pengar. Du väljer när, var och hur ofta du vill erbjuda dina tjänster."
        bullet_list={[
          { text: "Ta ut och passa hundar, tjäna pengar" },
          {
            text: "Välj när, var och hur ofta du vill erbjuda dina tjänster",
          },
          {
            text: "Du och hundägaren kommer överens om din ersättning. Få betalt via banköverföring eller Swish",
          },
        ]}
        button_text="Kom igång"
        alt_text="En hundvakt som är på sitt första uppdrag"
      />
      <OurServices
        is_phone={isPhone}
        install_link={install_link}
        list={services}
        title="Tjänster du kan erbjuda"
      />
      <HowItWorksSection
        install_link={install_link}
        is_phone={isPhone}
        list={bullets}
        button_text="Kom igång"
      />
      <InfoSection
        install_link={install_link}
        is_phone={isPhone}
        image={require("../assets/how_much_i_make.webp")}
        title={"Hur mycket tjänar jag som hundvakt?"}
        analytics={{
          event_name: APP_INSTALL_CLICK,
          args: {
            where: "INFO_SECTION_HOW_MUCH_DOES_WOOFER_MAKE",
          },
        }}
        text={
          "Hur mycket du tjänar är upp till dig! Du väljer själv vilka och hur många uppdrag du vill ansöka till. När du ansöker så förhandlar du och hundägaren vilken ersättning du kommer att få. När du slutfört ett uppdrag väljer du om du vill få din utbetalning via Swish eller Banköverföring."
        }
        buttonText={"Kom igång"}
        button_link={install_link}
        alt_text={"Hundvakt som leker med en hund"}
      />
      <FaqQuestionSection
        install_link={install_link}
        is_phone={isPhone}
        questions={faq_questions}
      />
    </div>
  );
}

function getRelevantFaqQuestions() {
  return FAQ_QUESTIONS.filter((question) =>
    ["woofer"].includes(question.category)
  );
}

const services = [
  {
    title: "Promenad",
    text: "Promenad i 30, 60 eller 90 minuter",
    large_screen_text: "Promenad i 30, 60 eller 90 minuter",
    emoji: "🐾",
  },
  {
    title: "Hundpassning",
    text: "Passning i upp till 12 timmar",
    large_screen_text: "Passning i upp till 12 timmar hos dig eller hundägaren",
    emoji: "🐶",
  },
  {
    title: "Övernattning",
    text: "Minst en natts hundpassning",
    large_screen_text: "Passning i minst 1 dygn hos dig eller hundägaren",

    emoji: "🏠",
  },
  {
    title: "Hämta & lämna",
    text: "Ta hunden från en plats till en annan",
    large_screen_text: "Ta hunden från en plats till en annan",
    emoji: "🐕",
  },
];
const bullets = [
  {
    title: "Verifiera dig och skapa din profil",
    subtitle:
      "Woof tar säkerhet på största allvar. Därför är första steget för att bli en hundvakt att verifiera dig via BankID.\n Därefter, vägleder vi dig genom byggandet av din profil. Ladda upp profilbilder och berätta för hundägare om varför du är en bra hundvakt.",
    image: require("../assets/bygg_profil.webp"),
    alt_text: "Börja med att bygga din profil för att bli en hundvakt",
  },

  {
    title: "Invänta granskning",
    subtitle:
      "Woof-teamet granskar din ansökan och gör en kontroll i belastningsregistret. Granskningan tar vanligtvis max ett helgfritt dygn efter att du skapat din profil.",
  },
  {
    title: "Ansök till uppdrag",
    subtitle:
      "Utforska uppdrag nära dig och ansök till de som intresserar dig! När du ansöker föreslår du för hundägaren hur mycket du vill få betalt för uppdraget.\n Se till att läsa igenom uppdragsbeskrivningen noggrannt och vara personlig i din ansökan.",
    image: require("../assets/ansok_uppdrag.webp"),
    alt_text: "Ansök till uppdrag",
  },
  {
    title: "Bli vald, få betalt",
    subtitle:
      "Bli antagen till uppdraget och genomför tjänsten. Efter att du genomfört uppdraget får du välja om du vill få din utbetalning via Swish eller via banköverföring.\n Swish utbetalningar får du direkt på kontot, men Woof tar en avgift på 7.5% av utbetalningsbeloppet. Om du väljer banköverföring får du betalt inom 7 dagar och Woof tar inga extra avgifter.",
    image: require("../assets/get_payed.webp"),
    alt_text: "Få betalt",
  },
];
