import { Helmet } from "react-helmet-async";
import BlueInfoSection from "../components/blue_info_section";
import HeroSection from "../components/hero_section";
import InfoSection from "../components/info_section";
import { APP_INSTALL_CLICK, copyParamsToNewLink } from "../services/analytics";
import OurServices from "../components/our_services";

export default function PaymentInfo(props) {
  const { install_link, isPhone } = props;

  return (
    <div className="Body">
      <Helmet>
        <title>
          Hundvakt pris | Vad kostar det att boka hundvakt via Woof?
        </title>
        <meta
          name="description"
          content="När du bokar en hundvakt via Woof så betalar du inte förrän du fått tillbaka hunden efter uppdraget. Du och hundvakten förhandlar ersättning."
        />
      </Helmet>
      <HeroSection
        is_phone={isPhone}
        install_link={install_link}
        mobile_image={require("../assets/payments_hero_mobile.webp")}
        mobile_title="Hur mycket kostar det att boka hundvakt?"
        desktop_title="Hur mycket kostar det att boka hundvakt?"
        bullet_list={[
          {
            text: "Du och hundvakten kommer tillsammans fram till ersättning",
          },

          {
            text: "Du betalar din hundvakt via appen efter att du fått tillbaka din hund",
          },
          {
            text: "Woof är gratis att ladda ner",
          },
        ]}
        desktop_image={require("../assets/payments_hero_image_desktop.webp")}
        desktop_subtitle="Läs mer om hur betalningar fungerar när du hittar och bokar hundvakt via Woof"
        alt_text="Betalningar i Woof-appen"
      />
      {isPhone ? (
        <BlueInfoSection
          title={"Säkra betalningar via Stripe"}
          text={
            "Woof använder sig av Stripe för att hantera betalningar mellan hundägare och hundvakter. Alla betalningar hanteras på ett säkert sätt."
          }
        />
      ) : (
        <div></div>
      )}
      <OurServices
        is_phone={isPhone}
        install_link={install_link}
        list={payment_benefits}
        title="Trygga, transparenta betalningar"
        button_text={"Ladda ner appen"}
        button_link={install_link}
        analytics={{
          event_name: APP_INSTALL_CLICK,
          args: {
            where: `WOOF_BENEFITS`,
          },
        }}
      />
      <WhatDoesItCost
        isPhone={isPhone}
        button_Link={install_link}
        button_text={"Hitta hundvakt"}
      />
      <InfoSection
        image={require("../assets/playing_with_ball.webp")}
        title={"När betalar jag?"}
        text={
          "När du hittat, valt och bokat en hundvakt eller hundrastare via Woof så betalar du inte förrän du fått tillbaka din hund. Du betalar din hundvakt eller hundrastare via appen när uppdraget är avslutat."
        }
        buttonText={"Ladda ner appen"}
        button_link={install_link}
        is_phone={isPhone}
        alt_text={"Hund och hundvakt hemma och leker"}
        is_grey={true}
        analytics={{
          event_name: APP_INSTALL_CLICK,
          args: {
            where: `INFO_SECTION_WHEN_DO_I_PAY`,
          },
        }}
      />
      <InfoSection
        image={require("../assets/how_much_to_pay.webp")}
        title={"Hur går betalningar till?"}
        text={
          "När du behöver hjälp med en av våra tjänster, skapar du först en förfrågan gratis via appen. Verifierade hundvakter och hundrastare ansöker sedan om att få hjälpa dig och din hund. När du har fått en ansökan du gillar, bokar du hundvakten eller hundrastaren. När uppdraget är avslutat och din hund lämnats tillbaka till dig betalar du din hundvakt eller hundrastare via appen."
        }
        buttonText={"Hitta hundvakt"}
        button_link={install_link}
        is_phone={isPhone}
        alt_text={"Hund och hundvakt på promenad"}
        analytics={{
          event_name: APP_INSTALL_CLICK,
          args: {
            where: `INFO_SECTION_HOW_DO_PAYMENTS_WORK`,
          },
        }}
      />
      <InfoSection
        image={require("../assets/overnattning-safety.webp")}
        title={"Hur funkar avbokningar?"}
        text={
          "Skulle din hundvakt avboka så avbryts din betalning. Då behöver du alltså inte betala någonting. Behöver du som är hundägare avboka gäller hundvaktens avbokningspolicy. Du kan läsa om alla hundvakters avbokningspolicy i deras profil."
        }
        buttonText={"Ladda ner appen"}
        button_link={install_link}
        is_phone={isPhone}
        is_grey={true}
        alt_text={"En glad hund hemma hos sin hundvakt"}
        analytics={{
          event_name: APP_INSTALL_CLICK,
          args: {
            where: `INFO_SECTION_HOW_DO_CANCELLATIONS_WORK`,
          },
        }}
      />
    </div>
  );
}

export function WhatDoesItCost(props) {
  const is_grey = props.is_grey ?? false;
  return (
    <InfoSection
      is_grey={is_grey}
      image={require("../assets/dog_in_park.webp")}
      title={"Hur mycket kostar det?"}
      text={
        "Woof lägger stor vikt i flexibilitet. Precis som alla människor, så är alla hundar olika. Därför har Woof ingen fast prissättning. När en hundvakt ansöker för att hjälpa dig och din hund, är det upp till dig och hundvakten att tillsammans komma fram till en ersättning som passar er båda."
      }
      buttonText={props.button_text ?? "Läs mer"}
      button_link={props.button_link ?? copyParamsToNewLink("/hundvakt-pris")}
      is_phone={props.isPhone}
      alt_text={"Hund och hundvakt ute i parken"}
      analytics={{
        event_name: APP_INSTALL_CLICK,
        args: {
          where: `INFO_SECTION_HOW_MUCH_SHOULD_I_PAY`,
        },
      }}
    />
  );
}
const payment_benefits = [
  {
    title: "Stripe",
    text: "Betalningar hanteras via Stripe",
    large_screen_text: "Betalningar hanteras via Stripe",

    emoji: "✔️",
  },
  {
    title: "Prisvärt",
    text: "Förhandla pris med hundrastaren",
    large_screen_text: "Förhandla pris med hundrastaren",
    emoji: "💳",
  },
  {
    title: "Kommission",
    text: "Woof tar 9% i kommission",
    large_screen_text: "Woof tar 9% i kommission",
    emoji: "💜",
  },

  {
    title: "Betala efter",
    text: "Betala när du fått tillbaka hunden",
    large_screen_text: "Betala när du fått tillbaka hunden",

    emoji: "⏰",
  },
];
