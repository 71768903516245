import "./rounded_woof_button.css";

export default function WoofRoundedButton(props) {
  return (
    <a
      href={props.link}
      onClick={props.onClick}
      className="Woof-rounded-button-link"
    >
      <div
        className={
          props.secondary
            ? "Woof-rounded-button-alt"
            : props.grey_rounded
            ? "Woof-rounded-button-alt2"
            : "Woof-rounded-button"
        }
      >
        {props.text}
      </div>
    </a>
  );
}
