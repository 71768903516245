import { why_woof_bullets } from "../App";
import "./hero_section.css";
import WoofRoundedButton from "./rounded_woof_button";
import WoofBullet from "./woof_bullet";
import "../App.css";
import { APP_INSTALL_CLICK, LogEvent } from "../services/analytics";

function logInstallClick() {
  console.log("Logging install click");
  LogEvent(APP_INSTALL_CLICK, {
    where: "HERO_SECTION",
  });
}

export default function HeroSection(props) {
  const bullet_list = props.bullet_list ?? why_woof_bullets;
  const { skip_button } = props;
  return (
    <div className="Hero-section">
      <Image
        is_phone={props.is_phone}
        install_link={props.install_link}
        mobile_image={props.mobile_image}
        desktop_image={props.desktop_image}
        desktop_title={props.desktop_title}
        desktop_subtitle={props.desktop_subtitle}
        button_text={props.button_text}
        alt_text={props.alt_text}
      />

      {props.is_phone ? (
        <div className="Hero-text-content">
          <h1 className="Page-title">{props.mobile_title}</h1>
          {props.mobile_subtitle ? (
            <div className="Hero-subtitle-mobile">
              <p>{props.mobile_subtitle}</p>
            </div>
          ) : (
            <div className="Hero-bullets">
              {bullet_list.map((bullet) => {
                const index = bullet_list.indexOf(bullet);
                return <WoofBullet text={bullet.text} key={index} />;
              })}
            </div>
          )}

          {skip_button ? (
            <div></div>
          ) : (
            <WoofRoundedButton
              text={props.button_text ?? "Ladda ner appen"}
              link={props.install_link}
              onClick={() => {
                logInstallClick();
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}

function Image(props) {
  if (!props.is_phone) {
    return (
      <div className="Hero-image">
        <div className="Large-screen-hero-title-section">
          <h1 className="Large-screen-hero-title">{props.desktop_title}</h1>
          <p className="Large-screen-hero-subtitle">{props.desktop_subtitle}</p>
          <WoofRoundedButton
            text={props.button_text ?? "Ladda ner appen"}
            link={props.install_link}
            onClick={() => {
              logInstallClick();
            }}
          />
        </div>
        <div className="Large-screen-hero-image-container">
          <img
            src={props.desktop_image}
            className="Large-screen-hero-image"
            alt={props.alt_text}
          ></img>
        </div>
      </div>
    );
  }
  return (
    <img
      src={props.mobile_image}
      className="Hero-image"
      alt={props.alt_text}
    ></img>
  );
}

/*   border-radius: 10px 10px 0 0;
 */
