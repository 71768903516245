import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { insertGaCode, onCookiesAccepted, removeCookies } from "../App";
import { setUserAnalyticsParams } from "./analytics";

export const GlobalStateContext = createContext();

export const LOADING_STATE = "loading_state";
export const CONTENT_STATE = "content_state";
export const ERROR_STATE = "error_state";

export function getParameterByName(name) {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const default_web_branch_id = "SM9klJyyDzb";
const mobile_base_app_link = "https://woofapp.app.link/";

export const initialAddress = {
  address: "Stockholm, Sverige",
  geoloc: {
    lat: 59.3293,
    lng: 18.0686,
  },
};

function sortWoofersList(wooferModelList) {
  let ratedWoofers = wooferModelList.filter(
    (woofer) => woofer.avg != null && !isNaN(woofer.avg) && woofer.avg > 4
  );

  ratedWoofers.sort((a, b) => {
    return a.distance - b.distance;
  });

  let nonRated = wooferModelList.filter(
    (woofer) =>
      ratedWoofers.filter((ratedWoofer) => ratedWoofer.id === woofer.id)
        .length === 0
  );

  nonRated.sort((a, b) => {
    return a.distance - b.distance;
  });

  return [...ratedWoofers, ...nonRated];
}

export const GlobalStateProvider = ({ children }) => {
  const [install_link, setInstallLink] = useState(
    `${mobile_base_app_link}${default_web_branch_id}`
  );

  const storedAddress = JSON.parse(localStorage.getItem("address"));
  console.log("Stored address", storedAddress);

  const [address, setAddress] = useState(storedAddress ?? initialAddress);

  const [gclid, setGclid] = useState(null);
  const [isPhone, setIsPhone] = useState(window.innerWidth < 768);

  const [woofers, setWoofers] = useState([]);

  const [woofersState, setWoofersState] = useState(LOADING_STATE);

  async function fetchWoofers(geoloc, force = false) {
    const toSetGeoloc = geoloc ?? address.geoloc;
    try {
      if (!force) {
        const lastUpdated = localStorage.getItem("woofersLastUpdated");

        const oneDayAgo = new Date().getTime() - 1000 * 60 * 60 * 24;

        if (lastUpdated && parseInt(lastUpdated) > oneDayAgo) {
          const storedWoofers = localStorage.getItem("woofers");

          if (storedWoofers) {
            console.log("Using stored woofers");

            const wooferModelList = JSON.parse(storedWoofers).map((json) => {
              const dataToUse = { ...json, ...json.data };
              return new WooferModel(dataToUse);
            });

            setWoofers(sortWoofersList(wooferModelList));
            setWoofersState(CONTENT_STATE);

            return;
          }
        }
      }

      setWoofersState(LOADING_STATE);
      console.log("FETCHWOOFERS WITH GEOLOC: " + toSetGeoloc);

      const geolocString = `?geoloc=${toSetGeoloc.lat},${toSetGeoloc.lng}`;
      const url = `https://europe-west1-woof-react-landing.cloudfunctions.net/getWoofers/${geolocString}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }
      const data = await response.json();

      console.log("Response from fetchWoofers", data);

      const toSetWoofers = data.data.data.sections[0].items;

      const wooferModelList = toSetWoofers.map((json) => {
        const dataToUse = { ...json, ...json.data };
        console.log("Data to use", dataToUse);
        return new WooferModel(dataToUse);
      });

      setWoofers(sortWoofersList(wooferModelList));
      setWoofersState(CONTENT_STATE);

      localStorage.setItem(
        "woofers_last_fetched_with_geoloc",
        JSON.stringify(toSetGeoloc)
      );
      localStorage.setItem("woofers", JSON.stringify(toSetWoofers));
      localStorage.setItem(
        "woofersLastUpdated",
        new Date().getTime().toString()
      );
    } catch (err) {
      console.error(
        "Error in fetchWoofers. Unable to fetch data. Please try again later.",
        err.message
      );
    }
  }

  useEffect(() => {
    console.log("Component mounted and initial setup running");
    fetchWoofers();
    insertGaCode();

    console.log("Consent set to denied, default");

    const cookieConsent = Cookies.get("CookieConsent");
    if (cookieConsent === "false" || cookieConsent === undefined) {
      console.log("Cookie consent is false");
      removeCookies();
    } else if (cookieConsent === "true") {
      const cookiesThatAreAllowed = Cookies.get("AllowedCookies");

      console.log("Allowed all cookies!");

      if (cookiesThatAreAllowed) {
        const allowedCookies = JSON.parse(cookiesThatAreAllowed);

        const hasAnalytics = allowedCookies.includes("Analys");
        const hasStatistics = allowedCookies.includes("Statistik");
        console.log(
          `Allowed cookies: ${cookiesThatAreAllowed}, hasAnalytics: ${hasAnalytics}, hasStatistics: ${hasStatistics}`
        );
        console.log(`ON ACCEPTED SOME STATE PROVIDER`);

        onCookiesAccepted(hasAnalytics, hasStatistics);
      } else {
        console.log(`ON ACCEPTED ALL STATE PROVIDER`);

        onCookiesAccepted(true, true);
      }
    }

    /*  Cookies.set("AllowedCookies", stringAccepted); */

    let toSetGclid = getParameterByName("gclid");
    if (toSetGclid) {
      localStorage.setItem("gclid", toSetGclid);
      setGclid(toSetGclid);
    } else {
      const storedGclid = localStorage.getItem("gclid");
      if (storedGclid) {
        toSetGclid = storedGclid;
        setGclid(storedGclid);
      }
    }

    const branchLinkId = getParameterByName("branchLinkId");
    if (branchLinkId && branchLinkId !== default_web_branch_id) {
      console.log(`Branch link ${install_link} is not the default`);

      const toSet = `${mobile_base_app_link}${branchLinkId}${
        toSetGclid === null ? "" : `?gclid=${toSetGclid}`
      }`;
      if (install_link !== toSet) {
        console.log(`Setting install link to ${toSet}`);
        setInstallLink(toSet);
      }

      localStorage.setItem("branchLinkId", branchLinkId);
      console.log(`Storing branch link id ${branchLinkId}`);
    } else {
      console.log("Branch link id is the default, getting stored id!");
      const storedBranchLinkId = localStorage.getItem("branchLinkId");
      if (storedBranchLinkId) {
        const toSet = `${mobile_base_app_link}${storedBranchLinkId}${
          toSetGclid === null ? "" : `?gclid=${toSetGclid}`
        }`;
        if (install_link !== toSet) {
          console.log(`Setting install link to ${toSet}`);
          setInstallLink(toSet);
        }
      }
    }
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    const lastGeolocWhenFetched = localStorage.getItem(
      "woofers_last_fetched_with_geoloc"
    );
    console.log(
      `GMAPS VALUE CHANGED; ${JSON.stringify(
        address
      )}, woofers_last_fetched_with_geoloc: ${lastGeolocWhenFetched}`
    );
    localStorage.setItem("address", JSON.stringify(address));

    if (address.geoloc) {
      const { lat, lng } = address.geoloc;

      let lastGeolocWhenFetchedJson = null;
      if (lastGeolocWhenFetched) {
        lastGeolocWhenFetchedJson = JSON.parse(lastGeolocWhenFetched);
      }

      if (lastGeolocWhenFetchedJson) {
        if (
          lastGeolocWhenFetchedJson.lat === lat &&
          lastGeolocWhenFetchedJson.lng === lng
        ) {
          console.log("GMAP Geoloc is the same, not fetching woofers");
          return;
        }
      }
      fetchWoofers(address.geoloc, true);
    }
  }, [address]);

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(`gclid changed to ${gclid}`);
    if (gclid) {
      setUserAnalyticsParams({
        gclid: gclid,
        gclid_just_for_us: gclid,
      });
    }
  }, [gclid]);

  useEffect(() => {
    console.log(`installLink changed to ${install_link}`);
    if (install_link) {
      setUserAnalyticsParams({
        install_link: install_link,
      });
    }
  }, [install_link]);

  return (
    <GlobalStateContext.Provider
      value={{
        install_link,
        isPhone,
        gclid,
        woofers,
        address,
        setAddress,
        woofersState,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

class WooferModel {
  constructor(json) {
    this.geoloc = json._geoloc;
    this.id = json.id;
    this.profileImage =
      json.processed_thumb_600_x_600_profile_image ?? json.profileImage;
    this.first_name = json["first name"];
    this.last_name = json["last name"];
    this.avg = json.avg;
    this.numberOfReviews = json.numberOfReviews;
    this.post_code = json["post number"];
    this.city = json.city;
    this.distance = json.distance;
    this.age = () => {
      const date = new Date(json.year, json.month, json.day);
      const diff = Date.now() - date.getTime();
      const ageDate = new Date(diff);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    };
    this.images = json.images;
    this.description = json.description;
    this.experienceBullets = convertExperienceBullets(json.experienceBullets);
    this.homeBullets = convertHomeBullets(json.homeData);
    this.experiencetext = json.experiencetext;
    this.whyWooferText = json.whyWalkerText;

    function convertExperienceBullets(fromDb) {
      if (!fromDb) return null;
      let list = [];
      for (const item of fromDb) {
        const answers = item["answer"];
        const question = item["question"].toLowerCase();
        if (question.includes("vilka hundar")) {
          let selectedSizes = [];
          const sizes = ["små", "medelstora", "stora"];
          for (const answer of answers) {
            selectedSizes.push(sizes[answer]);
          }

          selectedSizes.sort((a, b) => {
            const isFirst = a === "små";
            const isSecond = b === "medelstora";

            if (isFirst && !isSecond) {
              return -1;
            } else if (!isFirst && isSecond) {
              return 1;
            } else {
              return 0;
            }
          });

          list.push({
            raw: item,
            text: "Har erfarenhet av att passa {alternatives} hundar".replace(
              "{alternatives}",
              selectedSizes.join(", ")
            ),
          });
        } else {
          if (answers[0] === 0) {
            let toReturn = item["trueText"];

            if (toReturn.includes("hund(ar)")) {
              toReturn = "Har eller har haft hund(ar)";
            } else if (toReturn.includes("hjärt och lung")) {
              toReturn = "Har kunskap om HLR på hund";
            } else if (toReturn.includes("jobbat professionellt")) {
              toReturn = "Har jobbar professionellt med hundar";
            } else if (toReturn.includes("koppelträning")) {
              toReturn = "Har erfarenhet av koppelträning";
            } else if (toReturn.includes("hundträning")) {
              toReturn = "Har utövat hundträning";
            }

            list.push({
              raw: item,
              text: toReturn,
            });
          }
        }
      }
      return list;
    }

    function convertHomeBullets(data) {
      if (!data) return null;
      let list = [];
      for (const item of data) {
        const answers = item["answer"];
        const question = item["question"].toLowerCase();
        if (question.includes("jag bor i")) {
          list.push({
            text: "Bor i lägenhet",
            raw: item,
          });
        } else {
          if (answers[0] === 0) {
            let toReturn = item["trueText"];

            if (question.includes("soffan och i sängen")) {
              toReturn = "Hundar får vara i möbler";
            } else if (question.includes("rökfritt")) {
              toReturn = "Rökfritt hem";
            } else if (question.includes("nära en park?")) {
              toReturn = "Bor nära park";
            } else if (toReturn.includes("trädgård eller ett inhägnat")) {
              toReturn = "Har trädgård";
            } else if (toReturn.includes("Bor med barn")) {
              toReturn = "Bor med barn";
            }

            list.push({
              raw: item,
              text: toReturn,
            });
          }
        }
      }
      return list;
    }
  }
}
