import { LogEvent } from "../services/analytics";
import "./info_section.css";
import WoofRoundedButton from "./rounded_woof_button";

function LogInstallClick(analytics) {
  console.log("Logging install click");
  /* "NAVIGATION_CLICK", "CLICKED_SERVICE_BOX", null, `SERVICES_SECTION` */

  LogEvent(analytics.event_name, analytics.args ?? {});
}

export default function InfoSection(props) {
  const analytics = props.analytics;
  const is_grey = props.is_grey;
  const is_phone = props.is_phone;

  const grey_info_section_column = (
    <div className="Info-section-text-column-grey">
      <h4 className="Info-section-title">{props.title}</h4>
      <p className="Info-section-text">{props.text}</p>
      <div className="Info-section-button">
        <WoofRoundedButton text={props.buttonText} link={props.button_link} />
      </div>
    </div>
  );

  const image = (
    <img
      src={
        props.mobile_image != null && is_phone
          ? props.mobile_image
          : props.image
      }
      className="Info-section-image"
      alt={props.alt_text}
    />
  );

  return is_grey ? (
    <div className={"Info-section-grey"}>
      {is_phone ? image : grey_info_section_column}
      {is_phone ? grey_info_section_column : image}
    </div>
  ) : (
    <div className={"Info-section"}>
      {image}
      <div className="Info-section-text-column">
        <h4 className="Info-section-title">{props.title}</h4>
        <p className="Info-section-text">{props.text}</p>
        <div className="Info-section-button">
          <WoofRoundedButton
            text={props.buttonText}
            link={props.button_link}
            onClick={() => {
              LogInstallClick(analytics);
            }}
          />
        </div>
      </div>
    </div>
  );
}
