import TermsLayout from "../components/terms_layout";

export default function PrivacyPolicy(props) {
  const { install_link, isPhone } = props;

  return (
    <TermsLayout
      sections={sections}
      isPhone={isPhone}
      install_link={install_link}
      title="Dogi AB Integritetspolicy"
    />
  );
}

const section_one = {
  type: "text",
  title: "1. Introduktion",
  items: [
    "När du (”Användaren” eller ”du”) använder Dogi AB:s (“Dogi”) plattform och tjänster (som definierat i Dogis allmänna villkor (”Användarvillkoren”)), litar du på att Dogi hanterar dina personuppgifter. I denna integritetspolicy (”Integritetspolicyn”) förklaras vilka typer av personuppgifter Dogi samlar in, varför Dogi samlar in sådana uppgifter och vad Dogi använder personuppgifterna till – allt för att du ska kunna utöva dina rättigheter.",
  ],
};

const section_two = {
  type: "text",
  title: "2. Personlig data som Dogi samlar in",
  items: [
    "Personuppgifter är data eller information som kan användas för att identifiera en specifik individ (en fysisk person). De personuppgifter som kan behandlas består främst av sådana uppgifter som du direkt eller indirekt tillhandahåller Dogi, t.ex. i samband med din ansökan om registrering av ett konto hos Dogi och när du använder mobilapplikationen (”Applikationen”), Dogis webbplats (”Webbplatsen”) eller andra tjänster som tillhandahålls av Dogi från tid till annan, eller när du har kontakt med Dogi (”Tjänsterna”). Sådana personuppgifter samlas in av Dogi för att möjliggöra tillhandahållandet av de nämnda tjänsterna och kan inkludera information såsom nedan.",

    {
      subtitle: "Kontaktinformation med mera",
      items: [
        "När du väljer att ansöka om registrering av ett konto hos Dogi och/eller när du använder tjänsterna kommer Dogi att samla in personuppgifter såsom ditt namn, adress, e-postadress, mobiltelefonnummer, personnummer och annan information som du väljer att tillhandahålla på grund av de tjänster du avser att använda.",
      ],
    },
    {
      subtitle: "Platsinformation",
      items: [
        "När du använder vissa tjänster samlar Dogi in information om din mobiltelefons geografiska plats, om du har tillåtit applikationen att få åtkomst till platsinformationstjänster.",
      ],
    },
    {
      subtitle: "Betalningsinformation",
      items: [
        "När du genomför ett köp i applikationen eller på webbplatsen, ombeds du att ange betalningsinformation, såsom betalkortsuppgifter eller annan information relevant för den betalningsmetod du har valt.",
      ],
    },
    {
      subtitle: "Kontoinformation",
      items: [
        "I samband med att du registrerar dig kan Dogi samla in uppgifter såsom din bank, kontonummer eller annan relevant information för att kunna erhålla betalning.",
      ],
    },
    {
      subtitle: "Teknisk information",
      items: [
        "I samband med att du laddar ner applikationen och/eller använder tjänsterna kan viss teknisk information eller koder, såsom din mobiltelefons unika enhets-ID eller modell, samlas in.",
      ],
    },
  ],
};

const section_three = {
  type: "text",
  title: "3. Ändamål för behandling av personuppgifter",
  items: [
    "Dogi registrerar och behandlar personuppgifter främst för att tillhandahålla, underhålla och förbättra plattformen och tjänsterna, administrera plattformen och tjänsterna samt för att tillhandahålla relevant support.",
    "Dogi behandlar även personuppgifter för att fullgöra sina skyldigheter enligt gällande lagar, föreskrifter och beslut från myndigheter.",
    "Dessutom behandlas personuppgifter för att möjliggöra erbjudanden av vissa andra tjänster och produkter samt för att informera om erbjudanden och nyheter från Dogi och andra partners till Dogi.",
    "Behandling av personuppgifter genomförs även för statistikändamål och bedömningar, för att utveckla och förbättra plattformen och tjänsterna.",
    "Dessutom kan Dogi behandla dina personuppgifter för att bistå dig när du ansöker om registrering av ett konto med Dogi och för att tillhandahålla ytterligare information om tjänsterna, till exempel om din ansökan har avbrutits och ansökningsprocessen därför inte har slutförts.",
  ],
};

const section_four = {
  type: "text",
  title: "4. Åtkomst till registrerade personuppgifter",
  items: [
    "Du har rätt att kostnadsfritt, en gång per kalenderår, få information om dina personuppgifter som Dogi har registrerat och behandlar, hur de registrerade och behandlade personuppgifterna har samlats in, vilka ändamål personuppgifterna har behandlats för och till vilka tredje parter personuppgifterna kan ha överförts (”Registerutdrag”). En begäran om ett Registerutdrag måste vara skriftlig och undertecknad av dig. Begäran ska skickas till Dogi AB, Brahegatan 3, 114 37 Stockholm, Sverige, eller via e-post till Dogis support (med en skannad kopia av din skriftliga begäran bifogad).",
  ],
};

const section_five = {
  type: "text",
  title: "5. Rätt till rättelse med mera.",
  items: [
    "Om du upptäcker att personuppgifter som registrerats av Dogi är felaktiga, otillräckliga eller behandlas i strid med ovanstående ändamål (se avsnitt 3 ovan), den svenska personuppgiftslagen eller annan relevant lagstiftning, kan du begära att Dogi rättar, blockerar eller raderar sådana registrerade personuppgifter. En begäran om sådan rättelse, blockering eller radering ska göras via e-post till Dogis support.",
  ],
};

const section_six = {
  type: "text",
  title: "6. Marknadsföring",
  items: [
    "Dogi och dess partners kan rikta erbjudanden till dig via exempelvis applikationen, e-post, post och SMS. Marknadsföring som riktar sig direkt till dig ska följa gällande lagar, föreskrifter, uppförandekoder och etiska riktlinjer. Om du inte önskar ta emot marknadsföring från Dogi kan du kontakta Dogis support eller skicka en begäran genom att skicka ett brev till Dogi AB, Brahegatan 3, 114 37 Stockholm, Sverige.",
  ],
};

const section_seven = {
  type: "text",
  title: "7. Skydd av personuppgifter",
  items: [
    "Dogi tar frågan om säkerheten för personuppgifter mycket seriöst. I den mån Dogi behandlar och lagrar dina personuppgifter görs detta på servrar som är placerade inom EU. När dina personuppgifter lagras vidtar Dogi alla nödvändiga och rimliga tekniska och organisatoriska åtgärder för att skydda dina personuppgifter mot obehörig åtkomst, ändring eller förstörelse. Åtkomst till information är strikt begränsad på olika sätt (till exempel genom användarauktorisering och lösenordskrav, som endast tilldelas anställda som behöver åtkomst till sådan information för att kunna utföra sina arbetsuppgifter). Andra säkerhetsåtgärder inkluderar kryptering, brandväggar samt fysiska begränsningar och restriktioner vid åtkomst till byggnader och filer.",
  ],
};
const section_eight = {
  type: "text",
  title: "8. Överföring av personuppgifter till tredje parter med mera.",
  items: [
    "Dogi kan dela insamlade personuppgifter i enlighet med integritetspolicyn. Dogi kan dela dina personuppgifter med företag inom samma koncern som Dogi eller med andra företag som Dogi samarbetar med, för ovanstående ändamål (se avsnitt 3 ovan) både inom och utanför EU. Om, och i den utsträckning, Dogi lagrar dina personuppgifter, sparas sådana uppgifter alltid hos Dogi eller hos företag inom samma koncern som Dogi.",
  ],
};

const section_nine = {
  type: "text",
  title: "9. Platsinformation",
  items: [
    "Dogi kan samla in, behandla och dela din mobiltelefons exakta geografiska plats i realtid för att tillhandahålla vissa av tjänsterna. För att möjliggöra insamling av sådan platsinformation måste du ha aktiverat funktionen ”tillåt platsåtkomst” på din mobiltelefon. Du kan när som helst återkalla ditt samtycke till att Dogi samlar in, behandlar och delar sådan platsinformation genom att sluta använda tjänster som tillhandahålls av Dogi och som kräver platsinformation för att fungera korrekt, samt genom att stänga av funktionen ”tillåt platsåtkomst” på din mobiltelefon.",
  ],
};

const section_ten = {
  type: "text",
  title: "10. Cookies",
  items: [
    "Dogi använder kakor (cookies) för att optimera tjänsterna, utföra statistiska utvärderingar, genomföra analyser och för marknadsföring. En kaka är en textfil som lagras på exempelvis din dator eller mobiltelefon och möjliggör identifiering av din dator eller mobiltelefon. Kakor hjälper Dogi att anpassa exempelvis applikationen och webbplatsen efter dina krav som användare av tjänsterna.",
    "Det finns två typer av kakor (cookies): sessionskakor och permanenta kakor. En sessionskaka raderas automatiskt när en webbplats eller en applikation stängs ned. En permanent kaka lagras på en dator eller mobiltelefon under en viss tidsperiod. Dessa två typer av kakor kan placeras på en webbplats eller i en applikation av olika operatörer (första eller tredje part) för att lagras på din lagringsmedia (som din dators hårddisk eller mobiltelefons minne). En förstapartscookie placeras av operatören för den webbplats eller applikation du besöker eller använder. En tredjepartscookie placeras av en operatör som inte driver den webbplats eller applikation du besöker eller använder.",
    "Om du inte accepterar att kakor (cookies) lagras på din dator eller mobiltelefon kan du konfigurera detta i webbläsaren eller i applikationsinställningarna på din dator eller mobiltelefon. Du kan också ta bort kakor som lagras på din lagringsmedia. De flesta webbläsare och applikationer accepterar kakor som standard, men det finns också möjlighet att inte acceptera kakor eller välja att få en varning innan de lagras. Om du inte accepterar kakor kan detta påverka funktionaliteten för applikationen, webbplatsen eller andra tjänster.",
    "Följande tabell visar de olika kategorierna av kakor som används i Dogis verksamhet, varför de används och operatören som kan placera kakan, t.ex.:",
  ],
};

const section_eleven = {
  type: "table",
  columns: ["Typ av Cookie", "Beskrivning", "Varaktighet"],
  rows: [
    {
      items: [
        "Namn: _ga· Ändamål: Statistik· Ursprung: Google Analytics",
        "Används av GoogleAnalytics för att samlain data om antaletgånger en användarehar besökt webbplatsensamt datum för detförsta och senaste besöket. · Registrerar ett unikt ID som används för att generera statistisk data om hur besökaren använder webbplatsen. · Används av Google Analytics för att reglera begäran om hastighet.",
        "2 år",
      ],
    },
    {
      items: [
        "Namn: _ga_QZMZRG139C· Ändamål: Statistik· Ursprung: Google Analytics",
        "Används av GoogleAnalytics för att samlain data om antaletgånger en användarehar besökt webbplatsensamt datum för detförsta och senaste besöket. · Registrerar ett unikt ID som används för att generera statistisk data om hur besökaren använder webbplatsen. · Används av Google Analytics för att reglera begäran om hastighet.",
        "2 år",
      ],
    },
    {
      items: [
        "Namn: CookieConsent· Ändamål: Används för att lagra användarens samtycke till användning av cookies· Ursprung: Woof",
        "Används för att lagra användarens samtycke till användning av cookies",
        "1 år",
      ],
    },
    {
      items: [
        "Namn: AllowedCookies· Ändamål: Används för att lagra användarens samtycke till användning av cookies· Ursprung: Woof",
        "Används för att lagra användarens samtycke till användning av cookies",
        "1 år",
      ],
    },
    {
      items: [
        "Namn: _hjSessionUser· Ändamål: Analys · Ursprung: HotJar",
        "Denna cookie sätter ett användar-ID som är unik till hemsidan. Detta gör att data från flera sidvisningar på samma hemsida kan kopplas till samma användar-ID.",
        "1 år",
      ],
    },
    {
      items: [
        "Namn: _hjHasCachedUserAttributes· Ändamål: Analys · Ursprung: HotJar",

        "Denna cookie används för att lagra om användarattributen är cachade eller inte.",
        "Session",
      ],
    },
    {
      items: [
        "Namn: _hjUserAttributesHash· Ändamål: Analys · Ursprung: HotJar",
        "Denna cookie används för att lagra hash-värden för attribut som ändras. Det gör att vi vet när något attribut har ändrats och behöver uppdateras.",
        "2 minuter, förnyas var 30:e sekund",
      ],
    },
    {
      items: [
        "Namn: hj_session_· Ändamål: Analys · Ursprung: HotJar",

        "Denna cookie håller data för den aktuella sessionen. Det säkerställer att efterföljande förfrågningar i sessionen tillskrivs samma session",
        "30 minuters varaktighet, förlängd vid användaraktivitet",
      ],
    },
    {
      items: [
        "Namn: _hjCookieTest· Ändamål: Analys · Ursprung: HotJar",

        "Kontrollerar om Hotjar Tracking Code kan använda cookies. Om det kan, sätts ett värde på 1. Raderas nästan omedelbart efter att det skapats.",
        "Mindre än 100 ms varaktighet, cookie-utgångstid satt till sessionens varaktighet",
      ],
    },
    {
      items: [
        "Namn: _hjLocalStorageTest· Ändamål: Analys · Ursprung: HotJar",

        "Kontrollerar om Hotjar Tracking Code kan använda Local Storage. Om det kan, sätts ett värde på 1. Raderas nästan omedelbart efter att det skapats.",
        "Mindre än 100 ms varaktighet, cookie-utgångstid satt till sessionens varaktighet",
      ],
    },
    {
      items: [
        "Namn: _hjSessionStorageTest· Ändamål: Analys · Ursprung: HotJar",

        "Kontrollerar om Hotjar Tracking Code kan använda Session Storage. Om det kan, sätts ett värde på 1. Raderas nästan omedelbart efter att det skapats.",
        "Mindre än 100 ms varaktighet, cookie-utgångstid satt till sessionens varaktighet",
      ],
    },
    {
      items: [
        "Namn: _hjTLDTest· Ändamål: Analys · Ursprung: HotJar",

        "Försöker lagra _hjTLDTest-cookie för olika URL-substrängsalternativ tills det misslyckas. Möjliggör att vi försöker bestämma den mest generiska cookie-sökvägen att använda, istället för sidans värdnamn. Det innebär att cookies kan delas över subdomäner (där det är tillämpligt). Efter denna kontroll tas cookien bort. Sessionens varaktighet. Boolesk true/false-datatyp.",
        "Session",
      ],
    },
    {
      items: [
        "Namn: _hjClosedSurveyInvites· Ändamål: Analys · Ursprung: HotJar",

        "Sätts när en användare interagerar med en länkundersökningsinbjudningsmodal. Säkerställer att samma inbjudan inte visas igen om den redan har visats. ",
        "1 år",
      ],
    },
    {
      items: [
        "Namn: _hjDonePolls· Ändamål: Analys · Ursprung: HotJar",

        "Sätts när en användare fyller i en undersökning på webbplatsen. Säkerställer att samma undersökning inte visas igen om den redan har fyllts i.",
        "1 år",
      ],
    },
    {
      items: [
        "Namn: _hjMinimizedPolls· Ändamål: Analys · Ursprung: HotJar",

        "Sätts när en användare minimerar en undersökning på webbplatsen. Säkerställer att undersökningen förblir minimerad när användaren navigerar på webbplatsen.",
        "1 år",
      ],
    },
    {
      items: [
        "Namn: _hjShownFeedbackMessage· Ändamål: Analys · Ursprung: HotJar",

        "Sätts när en användare minimerar eller slutför en feedback-widget. Säkerställer att feedback-widgeten laddas som minimerad om användaren navigerar till en annan sida där den är inställd att visas.",
        "1 dag",
      ],
    },
  ],
};

const section_twelve = {
  type: "text",
  title: "11. Ändringar i integritetspolicyn",
  items: [
    "Dogi förbehåller sig rätten att ändra integritetspolicyn från tid till annan. Om några ändringar görs i integritetspolicyn som innebär någon ändring av hur Dogi behandlar dina personuppgifter, kommer Dogi att informera dig om sådana ändringar via exempelvis webbplatsen, applikationen eller via e-post. Om du fortsätter att använda tjänsterna efter att ha informerats om sådana ändringar anses du ha accepterat ändringarna. Dogi rekommenderar att du regelbundet läser integritetspolicyn för den senaste informationen om hur Dogi behandlar dina personuppgifter.",
  ],
};

const section_thirteen = {
  type: "text",
  title: "12. Kontaktinformation",
  items: [
    "Om du har frågor eller kommentarer angående integritetspolicyn, vänligen kontakta Dogis support. E-postadress: support@woofapp.se",
    "Frågor kommer att hanteras snabbt och besvaras normalt sett inom 3 dagar.",
  ],
};

const section_fourteen = {
  title: "13. Vilken information samlar Woof in?",
  type: "table",
  columns: ["Ändamål", "Behandling", "Data", "Varaktighet", "Förklaring"],
  rows: [
    {
      items: [
        "Identifiering av användarkonto",
        "Registrering av konto· Administration av konto",
        "Telefonnummer· E-postadress",
        "Obegränsat",
        "Telefonnummer eller e-postadress fungerar som unik identifikator för ditt konto",
      ],
    },
    {
      items: [
        "Registrering och administration av ägar-konto",
        "Registrering av konto· Administration av konto",
        "Förnamn· Efternamn· Personnummer (SSN)· Telefon· E-post· Enhet-ID· Platsinformation (valfritt)",
        "Så länge du är kund och 90 dagar efteråt",
        "Denna information krävs för att registrera ett konto, både för hundägare och Woofers.",
      ],
    },
    {
      items: [
        "Registrering och administration av Woofer-konton",
        "Registrering av konto· Administration av konto",
        "Personnummer (SSN)· Adress· Födelsedatum· Utbetalningskontonummer (IBAN)· Platsinformation",
        "Så länge du är kund och 90 dagar efteråt",
        "",
      ],
    },
    {
      items: [
        "Användar- och utbetalningsuppgifter för bokföringssyften",
        "Administration av konto· Bokföring· Rapportering av inkomst etc till myndigheter· Rapportering av eventuella kontrollupgifter till myndigheter",
        "Personnummer (SSN)· Förnamn· Efternamn· Utbetalningsspecifikation· Utbetalningskonto (IBAN)",

        "Så länge du är kund och minst 7 år efter det år som du avslutat ditt konto",
        "",
      ],
    },
    {
      items: [
        "Marknadsföring",
        "Nyhetsbrev· Aviseringar via SMS och e-post· Analys av hur appen används för att förbättra appens funktionalitet",
        "E-post· Telefonnummer· Adress· Namn",
        "Så länge du är kund och 90 dagar efteråt",
        "",
      ],
    },
    {
      items: [
        "Support",
        "Kommunikation som svar på frågor som mottagits av kundsupport· Undersökning av eventuella klagomål och supportfall.",
        "Förnamn· Efternamn· Personnummer· Telefon· E-post· Enhet-ID· Information som lämnats vid kommunikation med kundsupport.· Tidigare supportärenden.",
        "Så länge du är kund och 90 dagar efteråt",
        "",
      ],
    },
    {
      items: [
        "Betalningar (för hundägare)",
        "Initiera en betalning till en Woofer (hundrastare/hundvakt)",
        "Betalningskortsinformation (lagrad hos tredje part Stripe och kan inte nås av oss)· Specifikation på för vad som en betalning gjorts",
        "Så länge du är kund och minst 7 år efter det år som du avslutat ditt konto",
        "När du lägger till ditt betalningsmedel på Woof lagras information om ditt betalningsmedel via Stripe, en tredje parts tjänst som möjliggör för Woof att hantera betalningar mellan hundägare och Woofers (hundrastare/hundvakt).",
      ],
    },
    {
      items: [
        "Utbetalningar (för Woofers)",
        "Ta emot utbetalningar efter avslutade tjänster",
        "Utbetalningskontonummer (IBAN)· Specifikation på varför en utbetalning gjorts· Från vem som betalningen utförts",
        "Så länge du är kund och minst 7 år efter det år som du avslutat ditt konto",
        "",
      ],
    },
    {
      items: [
        "Teknisk utveckling av Woofs tjänster",
        "Analys av hur du använder Woofs tjänster",
        "Kön· Ålder· Stad· Klickdata· Information om din enhet",
        "12 månader",
        "",
      ],
    },
  ],
};

const sections = [
  section_one,
  section_two,
  section_three,
  section_four,
  section_five,
  section_six,
  section_seven,
  section_eight,
  section_nine,
  section_ten,
  section_eleven,
  section_twelve,
  section_thirteen,
  section_fourteen,
];
