import { Helmet } from "react-helmet-async";
import HeroSection from "../components/hero_section";
import HowItWorksSection from "../components/how_it_works_section";
import GreyTextSection from "../components/grey_text_section";
import {
  FollowLocationLive,
  VerificationAndReview,
  YouChooseWho,
} from "./safety";
import ChecklistSection from "../components/checklist_section";
import FaqQuestionSection from "../components/faq_question_section";
import { getRelevantFaqQuestionsForOwners } from "./home";
import { WhatDoesItCost } from "./payment_info";
import ReviewsSection from "../components/reviews_section";
import PopularNearbyWoofers from "../components/popular_nearby_woofers";

export default function DogSittingScreen(props) {
  const { install_link, isPhone, woofers, address, setAddress, woofersState } =
    props;

  return (
    <div className="Body">
      <Helmet>
        <title>
          Få hjälp med hundpassning | Hitta trygga hundpassare nära dig
        </title>
        <meta
          name="description"
          content="Woof gör det enkelt och tryggt att hitta hundpassare som kan hjälpa dig med hundpassning. Få hjälp direkt eller förboka."
        />
      </Helmet>
      <HeroSection
        is_phone={isPhone}
        install_link={install_link}
        mobile_image={require("../assets/hundpassning_hero.webp")}
        desktop_image={require("../assets/desktop_hero_hundpassning.webp")}
        mobile_title="Få trygg hjälp med hundpassning"
        desktop_title="Få trygg hjälp med hundpassning"
        desktop_subtitle="Woof hjälper dig hitta hundvakt på ett tryggt och smidigt sätt. Alla
        hundvakter är BankID-verifierade och du kan följa din hunds plats i
        realtid i appen."
        button_text={"Ladda ner appen"}
        alt_text="En glad vit hund"
      />
      <PopularNearbyWoofers
        address={address}
        woofers={woofers}
        setAddress={setAddress}
        woofersState={woofersState}
      />
      <YouChooseWho
        install_link={install_link}
        isPhone={isPhone}
        is_grey={false}
      />
      <HowItWorksSection install_link={install_link} is_phone={isPhone} />

      <VerificationAndReview isPhone={isPhone} is_grey={true} />
      <GreyTextSection install_link={install_link} is_phone={isPhone} />

      <ChecklistSection
        install_link={install_link}
        title={`Woof – ett hundpassningsval som är pålitligt och effektivt.
`}
        isPhone={isPhone}
        checklist={checklist}
      />
      <WhatDoesItCost
        install_link={install_link}
        isPhone={isPhone}
        is_grey={false}
      />
      <ReviewsSection isPhone={isPhone} />

      <FollowLocationLive isPhone={isPhone} is_grey={false} />

      <FaqQuestionSection
        install_link={install_link}
        is_phone={props.isPhone}
        questions={getRelevantFaqQuestionsForOwners()}
      />
    </div>
  );
}

const checklist = [
  {
    title: "För hundar i alla storlekar",
    subtitle:
      "Oavsett om din hund är stor eller liten, gammal eller ung, så försöker Woof hjälpa dig hitta en hundvakt som passar just din hund.",
  },
  {
    title: "Flexibla tider",
    subtitle:
      "Hitta en hundvakt som kan hjälpa dig med hundpassning när du behöver det.",
  },
  {
    title: "BankID-verifierade hundvakter",
    subtitle:
      "Alla hundvakter som är anslutna till Woof är verifierade med BankID.",
  },
  {
    title: "Hemma hos hundvakten eller hos dig",
    subtitle:
      "Välj om hundpassningen ska ske hemma hos hundvakten eller hos dig.",
  },
  {
    title: "Prisvärd hundpsassning",
    subtitle:
      "Olika hundvakter tar olika betalt för att hjälpa till med hundpassning. Du och hundvakten kommer överens om priset tillsammans.",
  },

  {
    title: "Håll dig uppdaterad",
    subtitle:
      "Följ hundens plats på kartan och få fotouppdateringar från hundvakten under tjänsten.",
  },

  {
    title: "Recensioner",
    subtitle:
      "Läs recensioner från andra hundägare innan du väljer en hundvakt.",
  },
];
/*  */
