import { GetWoofersContent } from "../pages/explore_woofers";
import {
  copyParamsToNewLink,
  LogEvent,
  NAVIGATION_CLICK,
} from "../services/analytics";
import { initialAddress } from "../services/state_provider";
import WoofAutocompleteField from "./auto_complete_field";
import { ShowMoreOrLessButton } from "./faq_question_section";

export default function PopularNearbyWoofers(props) {
  const { woofers, address, setAddress, woofersState } = props;
  const currentUrl = window.location.href;

  const use_hundrastare =
    currentUrl.includes("hundrastning") || currentUrl.includes("hamta-lamna");

  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "30px",
        paddingBottom: "30px",
        backgroundColor: "#f5f5f5",
        marginBottom: "30px",
      }}
    >
      <h2
        style={{
          paddingBottom: "20px",
        }}
      >
        {`Verifierade ${
          use_hundrastare ? "hundrastare" : "hundvakter"
        } i närområdet`}
      </h2>
      <WoofAutocompleteField
        address={address}
        setAddress={setAddress}
        initialAddress={initialAddress}
      />
      <GetWoofersContent
        woofersState={woofersState}
        woofers={woofers}
        max={3}
        shimmers={3}
      />
      <div
        style={{
          paddingBottom: "5px",
        }}
      >
        <ShowMoreOrLessButton
          callback={() => {
            LogEvent(NAVIGATION_CLICK, {
              where: "POPULAR_NEARBY_WOOFERS",
            });
          }}
          show_all={false}
          text={"Visa fler"}
          link={copyParamsToNewLink("/hitta-hundvakt")}
        />
      </div>
    </div>
  );
}
