import "./faq_question_section.css";
import { useState } from "react";

const arrow_down_svg =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M12,15.5a1.993,1.993,0,0,1-1.414-.585L5.293,9.621,6.707,8.207,12,13.5l5.293-5.293,1.414,1.414-5.293,5.293A1.993,1.993,0,0,1,12,15.5Z"/></g></svg>';

const arrow_up_svg =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><g id="_01_align_center" data-name="01 align center"><path d="M17.293,15.207,12,9.914,6.707,15.207,5.293,13.793,10.586,8.5a2,2,0,0,1,2.828,0l5.293,5.293Z"/></g></svg>';

export default function FaqQuestionSection(props) {
  const questions = props.questions;

  const [show_all, setShowAll] = useState(false);

  const max_length_non_expanded = 5;

  let to_return_questions = show_all
    ? questions
    : questions.length > max_length_non_expanded
    ? questions.slice(0, max_length_non_expanded)
    : questions;

  const has_more = questions.length > max_length_non_expanded;

  function expandOrCollapse() {
    if (show_all) scrollToElement("FAQ-question-container-title");
    setShowAll(!show_all);
  }
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    setTimeout(() => {
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      } else {
        console.error(`Element with id ${elementId} not found`);
      }
    }, 100);

    if (element) {
      setTimeout(() => {
        window.scrollBy(0, -100); // Scroll 100 pixels up
      }, 500);
    }
  }

  return (
    <div className="FAQ-questions-container">
      <h5
        style={{ textAlign: "center", paddingBottom: "15px" }}
        className="FAQ-question-container-title"
        id="FAQ-question-container-title"
      >
        {props.title ?? "Har du några frågor?"}
      </h5>

      <p className="FAQ-question-container-subtitle">
        Dags att prova på Woof? Då kanske du har några frågor du vill få svar
        på. Här är svar på några av de vanligaste frågorna vi får! Inte fått
        svar på din fråga?{" "}
        <a
          href="https://intercom.help/woof-3b60c1cf521b/sv"
          target="_blank"
          rel="noreferrer"
        >
          Kontakta support
        </a>
      </p>

      {to_return_questions.map((question, index) => {
        const is_last = index === to_return_questions.length - 1;
        return (
          <FAQ_QUESTION question={question} key={index} is_last={is_last} />
        );
      })}
      {has_more ? (
        <ShowMoreOrLessButton
          show_all={show_all}
          callback={expandOrCollapse}
        ></ShowMoreOrLessButton>
      ) : null}
    </div>
  );
}

export function ShowMoreOrLessButton(props) {
  const { show_all, callback, text, link } = props;

  function RenderButton() {
    return (
      <p
        style={{
          textAlign: "center",
          paddingTop: "15px",
          color: "#876AB4",
          fontWeight: "600",
          cursor: "pointer",
        }}
        onClick={() => callback()}
      >
        {text ?? (show_all ? "Visa mindre" : "Visa fler frågor")}
      </p>
    );
  }

  return link ? (
    <a
      href={link}
      style={{
        textDecoration: "none",
        color: "black",
      }}
    >
      <RenderButton></RenderButton>
    </a>
  ) : (
    <RenderButton></RenderButton>
  );
}

function FAQ_QUESTION(props) {
  const question = props.question;
  const [isExapanded, setIsExpanded] = useState(false);

  return (
    <div className="FAQ-question-card" style={{ marginBottom: "15px" }}>
      <div
        className="FAQ-question-card-question-row"
        aria-hidden="true"
        onClick={() => setIsExpanded(!isExapanded)}
      >
        <p
          className="FAQ-question-text"
          style={{
            color: isExapanded ? "#876AB4" : null,
            fontWeight: isExapanded ? "600" : null,
          }}
        >
          {question.q}
        </p>
        <img
          className="Dropdown-arrow"
          alt="Dropdown-arrow"
          src={`data:image/svg+xml;utf8,${encodeURIComponent(
            isExapanded ? arrow_up_svg : arrow_down_svg
          )}`}
        ></img>
      </div>
      {isExapanded ? (
        <div className="FAQ-question-card-answer">
          <NewlineText text={question.a ?? ""} />

          {/* new_tab */}
          {question.link != null ? (
            question.new_tab ? (
              <a
                href={question.link}
                style={{ color: "#876AB4" }}
                target="_blank"
                rel="noreferrer"
              >
                {question.link_text}
              </a>
            ) : (
              <a href={question.link} style={{ color: "#876AB4" }}>
                {question.link_text}
              </a>
            )
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

function NewlineText(props) {
  const text = props.text;

  return text.split("\n").map((str) => (
    <div>
      <p>{str}</p>
      <br />
    </div>
  ));
}
